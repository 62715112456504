import { createApp } from 'vue'
import App from './App.vue'
var widgets= require('cesium/Widgets/widgets.css');
import "video.js/dist/video-js.css";

var app = createApp(App);
import router from './router'
app.use(router);

import {LeftOutlined,RightOutlined,PlusOutlined,LoadingOutlined,DownOutlined,UserOutlined,SearchOutlined,CloseOutlined,CheckOutlined,DoubleLeftOutlined,DoubleRightOutlined,DeleteOutlined,FormOutlined,CaretRightOutlined,CaretDownOutlined,SwapOutlined,SettingOutlined,HomeOutlined,BranchesOutlined,TableOutlined,DatabaseOutlined,BlockOutlined,AppstoreOutlined,BorderOutlined} from "@ant-design/icons-vue";
app.component("LeftOutlined",LeftOutlined);
app.component("RightOutlined",RightOutlined);
app.component("PlusOutlined",PlusOutlined);
app.component("LoadingOutlined",LoadingOutlined);
app.component("DownOutlined",DownOutlined);
app.component("UserOutlined",UserOutlined);
app.component("SearchOutlined",SearchOutlined);
app.component("CloseOutlined",CloseOutlined);
app.component("CheckOutlined",CheckOutlined);
app.component("DoubleLeftOutlined",DoubleLeftOutlined);
app.component("DoubleRightOutlined",DoubleRightOutlined);
app.component("DeleteOutlined",DeleteOutlined);
app.component("FormOutlined",FormOutlined);
app.component("CaretRightOutlined",CaretRightOutlined);
app.component("CaretDownOutlined",CaretDownOutlined);
app.component("SwapOutlined",SwapOutlined);
app.component("SettingOutlined",SettingOutlined);
app.component("HomeOutlined",HomeOutlined);
app.component("BranchesOutlined",BranchesOutlined);
app.component("TableOutlined",TableOutlined);
app.component("DatabaseOutlined",DatabaseOutlined);
app.component("BlockOutlined",BlockOutlined);
app.component("AppstoreOutlined",AppstoreOutlined);
app.component("BorderOutlined",BorderOutlined);

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
app.use(Antd)

import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
app.use(Vue3ColorPicker)

import VueGridLayout from 'vue-grid-layout';
app.use(VueGridLayout)

import colorpicker from "@/components/colorpicker";
app.component("colorpicker",colorpicker)

import mainHeader from "@/components/mainHeader";
app.component("mainHeader",mainHeader)

import breadcrumb from "@/components/breadcrumb"
app.component("breadcrumb",breadcrumb)

import valveHeader from "@/components/valveHeader"
app.component("valveHeader",valveHeader)

import homeBtn from "@/components/homeBtn"
app.component("homeBtn",homeBtn)

import homeMenu from "@/components/homeMenu"
app.component("homeMenu",homeMenu)

import axios from "axios";

const axios1 = axios.create({
    //baseURL:""
    baseURL:""
})
app.config.globalProperties.$axios = axios1;
// 添加请求拦截器
axios1.interceptors.response.use( function (response) {
        // 在发送请求之前进行操作
        return response;
    },
    function (err) {
        console.log("error",err);
        if(err.response.status==400){
            if(err.response.data=="未找到用户信息"){
                router.push("/login")
            }else if(err.response.data.hasOwnProperty("msg")&&err.response.data.msg.includes("用户名或密码错误")){
                common.showError("用户名或密码错误");
            }else if(err.response.data == "Non-static method requires a target."){
                common.showError("该小区下没有绑定的换热站，解绑失败!");
            }else{
                common.showError(err.response.data.toString());
            }
        }
        if(err.response.status==401){
            router.push("/login")
        }
        // 对请求错误进行操作
        return Promise.reject(err);
    }
);

const axios2 = axios.create({
    baseURL:""
})
app.config.globalProperties.$axios1 = axios2;
// 添加请求拦截器
axios2.interceptors.response.use( function (response) {
        // 在发送请求之前进行操作
        return response;
    },
    function (err) {
        console.log("error",err);
        if(err.response.status==401){
            router.push("/login")
        }
        // 对请求错误进行操作
        return Promise.reject(err);
    }
);
const axios_ip = axios.create({
    baseURL:ip_monitor.includes("http")?ip_monitor:(ip_monitor.includes("~")?(location.origin+ip_monitor):(window.location.protocol+"//"+window.location.hostname+":"+ip_monitor))
})
app.config.globalProperties.$axios_ip = axios_ip;

import cookie from "js-cookie";
app.config.globalProperties.$cookie = cookie;


import common from "@/until/until";
app.config.globalProperties.$showAlarm = common.showAlarm;
app.config.globalProperties.$fileToBase64 = common.fileToBase64;
app.config.globalProperties.$showError = common.showError;
app.config.globalProperties.$showSuccess = common.showSuccess;
app.config.globalProperties.$showErrTip = common.showErrTip;
app.config.globalProperties.$showWarmTip = common.showWarmTip;
app.config.globalProperties.$getTime = common.getTime;
app.config.globalProperties.$GetQueryString = common.GetQueryString;
app.config.globalProperties.$uploadFile = common.uploadFile;
app.config.globalProperties.$uploadPng = common.uploadPng;
app.config.globalProperties.$getCenter = common.getCenter;
app.config.globalProperties.$loadGaoDe = common.loadGaoDe;
app.config.globalProperties.$loadBaiDu = common.loadBaiDu;
app.config.globalProperties.$loadTengXun = common.loadTengXun;
app.config.globalProperties.$loadTdt = common.loadTdt;
app.config.globalProperties.$getZeiotApi = common.getZeiotApi;
app.config.globalProperties.$judgeNumber = common.judgeNumber;
app.config.globalProperties.$is_in = common.is_in;
app.config.globalProperties.$getMenuTypes = common.getMenuTypes;
app.config.globalProperties.$getModalOpenType = common.getModalOpenType;
app.config.globalProperties.$getVideoType = common.getVideoType;
app.config.globalProperties.$setView = common.setView;
app.config.globalProperties.$showAlert = common.showAlert;
app.config.globalProperties.$loadGaoDe_d = common.loadGaoDe_d;
app.config.globalProperties.$loadBaiDu_d = common.loadBaiDu_d;
app.config.globalProperties.$loadTengXun_d = common.loadTengXun_d;
app.config.globalProperties.$loadTdt_d = common.loadTdt_d;
app.config.globalProperties.$changeTerrain = common.changeTerrain;
app.config.globalProperties.$load3DTiles = common.load3DTiles;
app.config.globalProperties.$loadModel = common.loadModel;
app.config.globalProperties.$get_len = common.get_len;
app.config.globalProperties.$drawImageEntity = common.drawImageEntity;
app.config.globalProperties.$clearWebgl = common.clearWebgl;
app.config.globalProperties.$fontSize = common.fontSize;
app.config.globalProperties.$uploadExcel = common.uploadExcel;
app.config.globalProperties.$exportExcel = common.exportExcel;

import coordinate from "@/until/coordinate";
app.config.globalProperties.$coordinate = coordinate;

import {useFlexibleRem} from "@/until/useFlexibleRem";
useFlexibleRem()

app.mount('#app')
