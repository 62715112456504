<template>
  <div class="page">
    <breadcrumb :crumbs="crumbs"></breadcrumb>
    <div class="pageCont">
      <div class="searchCont">
        <div class="searchItem">
          <span class="searchTit"></span>
          <a-input v-model:value="keyword" autofocus placeholder="输入关键字" />
        </div>
        <div class="searchItem">
          <span class="searchTit">小区：</span>
          <a-select style="width: 120px"
              v-model:value="xiaoqu_search"
              show-search
              placeholder="选择小区"
              :options="xqList"
              :field-names='{"label":"community_name","value":"community_id"}'
              :filter-option="filterXQ" @change="chooseXQ_search"></a-select>
        </div>
        <div class="searchItem">
          <span class="searchTit">楼栋：</span>
          <a-select style="width: 120px"
              v-model:value="ld_search"
              show-search
              placeholder="选择楼栋"
              :options="ldList_search"
              :field-names='{"label":"building_name","value":"building_id"}'
              :filter-option="filterLD" @change="chooseLD_search"></a-select>
        </div>
        <div class="searchItem">
          <span class="searchTit">单元：</span>
          <a-select style="width: 120px"
              v-model:value="unit_search"
              show-search
              placeholder="选择单元"
              :options="unitList_search"
              :field-names='{"label":"unit_name","value":"unit_id"}'
              :filter-option="filterUnit" ></a-select>
        </div>
        <div class="searchItem">
          <a-button  class="searchBtn" @click="resetFun">重置</a-button>
          <a-button type="primary" class="searchBtn m_l_1" @click="search">查询</a-button>
        </div>
        <div class="searchItem">
          <a-button type="primary" class="searchBtn" @click="addFun">添加</a-button>
        </div>
        <div class="searchItem">
          <a-button type="danger" class="searchBtn" @click="delBatch">批量删除</a-button>
        </div>
        <div class="searchCont_r">
          <div class="searchItem">
            <a-button  class="searchBtn" @click="exportFun">导出excel</a-button>
          </div>
          <div class="searchItem">
            <a-button  class="searchBtn" ><a href="./template/户阀模板.xlsx" download="户阀模板.xlsx">下载模板</a></a-button>
          </div>
          <div class="searchItem">
            <a-upload
                name="file"
                action=""
                :customRequest="uploadFun"
            >
              <a-button type="primary" class="searchBtn" >导入Excel文件</a-button>
            </a-upload>
          </div>
        </div>
      </div>
      <a-table :row-selection="{  selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" class="m_t_2" :columns="columns" :data-source="myData" :pagination="false" :scroll="{ y: scroll_y }" >
        <template #bodyCell="{ column, record,index }">
          <template v-if="column.dataIndex === 'caozuo'">
            <a-button type="link" @click="editFun(index)">编辑</a-button>
            <a-popconfirm title="是否确定删除此项?" ok-text="是"
                          cancel-text="否" @confirm="delFun(record)">
              <a-button type="link" danger>删除</a-button>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
      <a-pagination class="pagination pages_box" v-model:current="pageIndex" :pageSize="pageSize"  :total="myDataNum" show-less-items size="small" show-size-changer @change="onShowSizeChange" />
    </div>
    <a-modal v-model:visible="editModal" :title="tantitle" :width="500" :maskClosable="false" :footer="null">
      <div class="modalForm custom_label">
        <a-form ref="formM" :model="editData"  autocomplete="off">
          <a-form-item label="小区" name="xq" >
            <a-select v-if="tantitle == '添加'"
                v-model:value="xiaoqu"
                show-search
                placeholder="选择小区"
                :options="xqList"
                :field-names='{"label":"community_name","value":"community_id"}'
                :filter-option="filterXQ" @change="chooseXQ"></a-select>
            <a-input v-model:value="editData.community_name" disabled v-else/>
          </a-form-item>
          <a-form-item label="楼栋" name="ld" >
            <a-select v-if="tantitle == '添加'"
                v-model:value="ld"
                show-search
                placeholder="选择楼栋"
                :options="ldList"
                :field-names='{"label":"building_name","value":"building_id"}'
                :filter-option="filterLD" @change="chooseLD"></a-select>
            <a-input v-model:value="editData.building_name" disabled v-else/>
          </a-form-item>
          <div style="display: flex;">
            <a-form-item style="width: 50%" label="单元" name="unit" >
              <a-select v-if="tantitle == '添加'"
                  v-model:value="unit"
                  show-search
                  placeholder="选择单元"
                  :options="unitList"
                  :field-names='{"label":"unit_name","value":"unit_id"}'
                  :filter-option="filterUnit" @change="chooseUnit"></a-select>
              <a-input v-model:value="editData.unit_name" disabled v-else/>
            </a-form-item>
            <a-form-item style="width: 50%" label="楼层" name="lc" >
              <a-select v-if="tantitle == '添加'"
                  v-model:value="lc"
                  show-search
                  placeholder="选择楼层"
                  :options="lcList"
                  :field-names='{"label":"storey_name","value":"storey_id"}'
                  :filter-option="filterLC" @change="chooseLC"></a-select>
              <a-input v-model:value="editData.storey_name" disabled v-else/>
            </a-form-item>
          </div>
          <div style="display: flex;">
            <a-form-item style="width: 50%" label="房屋" name="housesvalve_houses_id" :rules="[{ required: true, message: '请选择!' }]">
              <a-select v-if="tantitle == '添加'"
                  v-model:value="editData.housesvalve_houses_id"
                  show-search
                  placeholder="选择房屋"
                  :options="houseList"
                  :field-names='{"label":"houses_name","value":"houses_id"}'
                  :filter-option="filterHouse" ></a-select>
              <a-input v-model:value="editData.houses_name" disabled v-else/>
            </a-form-item>
            <a-form-item style="width: 50%" label="序号" name="housesvalve_sort" :rules="[{ required: true, message: '请输入!' }]">
              <a-input v-model:value="editData.housesvalve_sort"/>
            </a-form-item>
          </div>
          <div style="display: flex;">
            <a-form-item style="width: 50%" label="温室卡号" name="housesvalve_green_code" :rules="[{ required: true, message: '请输入!' }]" >
              <a-input v-model:value="editData.housesvalve_green_code"/>
            </a-form-item>
            <a-form-item style="width: 50%" label="温室型号" name="housesvalve_green_id" >
              <a-select
                  v-model:value="editData.housesvalve_green_id"
                  show-search
                  placeholder="选择"
                  :options="dpList"
                  :field-names='{"label":"dp_name","value":"dp_id"}'
                  :filter-option="filterDP" ></a-select>
            </a-form-item>
          </div>
          <div style="display: flex;">
            <a-form-item style="width: 50%" label="户阀卡号" name="housesvalve_code" :rules="[{ required: true, message: '请输入!' }]" >
              <a-input v-model:value="editData.housesvalve_code"/>
            </a-form-item>
            <a-form-item style="width: 50%" label="户阀型号" name="housesvalve_type_id" >
              <a-select
                  v-model:value="editData.housesvalve_type_id"
                  show-search
                  placeholder="选择"
                  :options="dpList"
                  :field-names='{"label":"dp_name","value":"dp_id"}'
                  :filter-option="filterDP" ></a-select>
            </a-form-item>
          </div>
        </a-form>
      </div>
      <div class="modelFooter">
        <div class="modal_btns">
          <a-button type="primary" class="modal_btn" size="middle" @click="confirm_save">保存</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "houseValveManagement",
  data(){
    return {
      crumbs:["户阀管理"],
      keyword:"",
      pageIndex:1,
      pageSize:10,
      myData:[],
      myDataNum:0,
      columns:[
        { title: '区域', dataIndex: 'zone_name', key: 'zone_name',align:"center" },
        { title: '小区', dataIndex: 'community_name', key: 'community_name',align:"center" },
        { title: '楼栋', dataIndex: 'building_name', key: 'building_name',align:"center" },
        { title: '单元', dataIndex: 'unit_name', key: 'unit_name',align:"center" },
        { title: '房号', dataIndex: 'houses_name', key: 'houses_name',align:"center" },
        { title: '温室卡号', dataIndex: 'housesvalve_green_code', key: 'housesvalve_green_code',align:"center" },
       /* { title: '温室型号', dataIndex: 'device_model_partition_name', key: 'device_model_partition_name',align:"center" },*/
        { title: '户阀卡号', dataIndex: 'housesvalve_code', key: 'housesvalve_code',align:"center" },
       /* { title: '户阀型号', dataIndex: 'device_model_partition_name', key: 'device_model_partition_name',align:"center" },*/
        { title: '操作', dataIndex: 'caozuo', key: 'caozuo',align:"center" },
      ],
      editModal:false,
      editData:{
        "housesvalve_houses_id": "",
        "housesvalve_green_id": "",
        "housesvalve_green_code": "",
        "housesvalve_type_id": "",
        "housesvalve_code": "",
        "housesvalve_sort": 0
      },
      tantitle:"添加",
      xqList:[],
      xiaoqu:"",
      xiaoqu_search:"",
      ldList:[],
      ldList_search:[],
      ld:"",
      ld_search:"",
      unitList:[],
      unitList_search:[],
      unit:"",
      unit_search:"",
      lcList:[],
      lc:"",
      houseList:[],
      house:"",
      dpList:[],
      selectedRowKeys:[],
      scroll_y:300,
      del_index:0
    }
  },
  mounted(){
    this.scroll_y = document.querySelectorAll(".page")[0].offsetHeight- 220;
    this.$axios.defaults.headers.common["Authorization"] = "bearer "+this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.$axios1.defaults.headers.common["Authorization"] = "bearer "+this.$cookie.get("token");
    this.$axios1.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.getData();
    this.getXQList();
    this.getDPList();

  },
  methods:{
    //获取户阀列表
    getData(){
      var url = "/heating/device-housesvalve/info/page";
      this.$axios.post(url,{
        "keyword": this.keyword,
        "pageSize":this.pageSize,
        "pageNo": this.pageIndex,
        "zone_id": "",
        "community_id": this.xiaoqu_search,
        "building_id": this.ld_search,
        "unit_id": this.unit_search
      }).then((res)=>{
        if(res.status == 200){
          this.myData = res.data.data.map((item)=>{
            return {
              ...item,
              key:item.housesvalve_id
            }
          });
          this.myDataNum = res.data.totalItems;
        }
      }).catch((res)=>{

      })
    },
    search(){
      this.pageIndex= 1;
      this.myData = [];
      this.getData();
    },
    addFun(){
      this.tantitle = "添加";
      this.editModal = true;
    },
    editFun(index){
      this.tantitle = "修改";
      this.editModal = true;
      this.editData = Object.assign({},this.myData[index]);
    },
    delFun(record){
      var url = "/heating/device-housesvalve/remove?id="+record.housesvalve_id;
      this.$axios.delete(url).then((res)=>{
        if(res.status == 200){
          this.$showSuccess("删除成功！");
          this.getData();
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    confirm_save() {
      this.$refs.formM.validateFields().then(() => {
        this.save();
      }, (err) => {
        console.log(err)
      })
    },
    save(){
      var url = "";
      if(this.tantitle == "添加"){
        url = "/heating/device-housesvalve/add";
        var data = JSON.parse(JSON.stringify(this.editData));
        data.housesvalve_sort = data.housesvalve_sort?parseFloat(data.housesvalve_sort):0
        this.$axios.post(url,data).then(res=>{
          if(res.status == 200){
            this.$showSuccess("保存成功！");
            this.editModal = false;
            this.getData()
          }
        }).catch((res)=>{
          console.log(res);
        })
      }
      if(this.tantitle == "修改"){
        url = "/heating/device-housesvalve/up";
        var data = {
          "housesvalve_houses_id": this.editData.housesvalve_houses_id,
          "housesvalve_green_id": this.editData.housesvalve_green_id,
          "housesvalve_green_code": this.editData.housesvalve_green_code,
          "housesvalve_type_id": this.editData.housesvalve_type_id,
          "housesvalve_code": this.editData.housesvalve_code,
          "housesvalve_sort": this.editData.housesvalve_sort?parseFloat(this.editData.housesvalve_sort):0,
          "housesvalve_id": this.editData.housesvalve_id
        };
        this.$axios.put(url,data).then((res)=>{
          if(res.status == 200){
            this.$showSuccess("保存成功！");
            this.editModal = false;
            this.getData()
          }
        }).catch((res)=>{
          console.log(res)
        })
      }
    },
    onShowSizeChange(page,pageSize) {
      this.pageIndex = page;
      this.pageSize = pageSize;
      this.myData = [];
      this.myDataNum = 0;
      this.getData();
    },
    //获取小区列表
    getXQList(){
      var url = "/heating/community/info";
      /*var url = "/heating/community/info/page";*/
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.xqList = res.data;
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    filterXQ(e,option){
      return option.community_name.includes(e);
    },
    //选择小区
    chooseXQ(){
      this.ld = "";
      this.ldList = [];
      this.unit = "";
      this.unitList = [];
      this.house = "";
      this.houseList = [];
      this.getLDList(this.xiaoqu,(data)=>{
        this.ldList = data;
      });
    },
    chooseXQ_search(){
      this.ld_search = "";
      this.ldList_search = [];
      this.unit_search = "";
      this.unitList_search = [];
      this.getLDList(this.xiaoqu_search,(data)=>{
        this.ldList_search = data;
      });
    },
    //获取楼栋列表
    getLDList(id,fun){
      var url = "/heating/building/info?community_id="+id;
      /*var url = "/heating/community/info/page";*/
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          if(fun){
            fun(res.data)
          }
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    filterLD(e,option){
      return option.building_name.includes(e);
    },
    //选择楼栋
    chooseLD(){
      this.unit = "";
      this.unitList = [];
      this.house = "";
      this.houseList = [];
      this.getUnitList(this.ld,(data)=>{
        this.unitList = data;
      });
    },
    chooseLD_search(){
      this.unit_search = "";
      this.unitList_search = [];
      this.getUnitList(this.ld_search,(data)=>{
        this.unitList_search = data;
      });
    },
    //获取单元列表
    getUnitList(id,fun){
      var url = "/heating/unit/info/page";
      /*var url = "/heating/community/info/page";*/
      this.$axios.post(url,{
        "keyword": "",
        "pageSize": 99,
        "pageNo": 1,
        "unit_building_id": id
      }).then((res)=>{
        if(res.status == 200){
          if(fun){
            fun(res.data.data);
          }
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    filterUnit(e,option){
      return option.unit_name.includes(e);
    },
    //选择单元
    chooseUnit(){
      this.lc = "";
      this.lcList = [];
      this.house = "";
      this.houseList = [];
      this.getLCList();
    },
    //获取楼层列表
    getLCList(){
      var url = "/heating/storey/info?storey_unit_id="+this.unit;
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.lcList = res.data;
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    filterLC(e,option){
      return option.storey_name.includes(e);
    },
    //选择楼层
    chooseLC(){
      this.house = "";
      this.houseList = [];
      this.getHouseList();
    },
    //获取房屋列表
    getHouseList(){
      var url = "/heating/houses/info?houses_storey_id="+this.lc;
      /*var url = "/heating/community/info/page";*/
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.houseList = res.data;
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    filterHouse(e,option){
      return option.houses_name.includes(e);
    },
    //获取型号列表
    getDPList(){
      var url = "/heating/zeiot/device-protocols-options";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.dpList = res.data;
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    filterDP(e,option){
      return option.dp_name.includes(e);
    },
    //导入
    uploadFun(e){
      this.$uploadExcel(e.file).then((obj)=>{
        var data = [];
        obj.data.forEach((item)=>{
          data.push({
            "zone_name": item["区域"]?item["区域"].toString():"",
            "community_name": item["小区"]? item["小区"].toString():"",
            "building_name": item["楼栋"]?item["楼栋"].toString():"",
            "unit_name": item["单元"]?item["单元"].toString():"",
            "storey_name": item["楼层"]?item["楼层"].toString():"",
            "houses_name": item["房号"]?item["房号"].toString():"",
            "housesvalve_green_type_name": item["温室型号"]?item["温室型号"].toString():"",
            "housesvalve_green_code": item["温室卡号"]?item["温室卡号"].toString():"",
            "housesvalve_type_name": item["户阀型号"]?item["户阀型号"].toString():"",
            "housesvalve_code": item["户阀卡号"]?item["户阀卡号"].toString():""
          })
        })
        this.saveBath(data);
      })
      return
    },
    saveBath(data){
      if(data.length<=0){
        return false
      }
      var url = "/heating/device-housesvalve/import";
      this.$axios.post(url,data).then((res)=>{
        if(res.status == 200){
          this.$showSuccess("导入成功！");
          this.getData();
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //重置
    resetFun(){
      this.keyword = "";
      this.xiaoqu_search = "";
      this.ld_search = "";
      this.unit_search = "";
    },
    onSelectChange(selectedRowKeys){
      this.selectedRowKeys = selectedRowKeys;
    },
    //批量删除
    delBatch(){
      if(this.selectedRowKeys.length<=0){
        return this.$showWarmTip("至少选择一项！");
      }
      this.del_index = 0;
      this.selectedRowKeys.forEach((item)=>{
        this.del_fun(item);
      })
    },
    del_fun(id){
      var url = "/heating/device-housesvalve/remove?id="+id;
      this.$axios.delete(url).then((res)=>{
        this.del_index = this.del_index+1;
        if(res.status == 200){
        }
        if(this.del_index == this.selectedRowKeys.length){
          this.selectedRowKeys = [];
          this.getData();
        }
      }).catch((res)=>{
        this.del_index = this.del_index+1;
        if(this.del_index == this.selectedRowKeys.length){
          this.selectedRowKeys = [];
          this.getData();
        }
        console.log(res);
      })
    },
    //导出
    exportFun(){
      var headers = {
        "区域":"区域",
        "小区":"小区",
        "楼栋":"楼栋",
        "单元":"单元",
        "房号":"房号",
        "温室卡号":"温室卡号",
        "户阀卡号":"户阀卡号",
      };
      var newData = [];
      var url = "/heating/device-housesvalve/info";
      this.$axios.post(url,{
        "keyword": this.keyword,
       /* "pageSize":this.pageSize,
        "pageNo": this.pageIndex,*/
        "zone_id": "",
        "community_id": this.xiaoqu_search,
        "building_id": this.ld_search,
        "unit_id": this.unit_search
      }).then((res)=>{
        if(res.status == 200){
          var Data = res.data;
          Data.forEach((item)=>{
            newData.push({
              "区域":item.zone_name,
              "小区":item.community_name,
              "楼栋":item.building_name,
              "单元":item.unit_name,
              "房号":item.houses_name,
              "温室卡号":item.housesvalve_green_code,
              "户阀卡号":item.housesvalve_code,
            })
          })
          this.$exportExcel(newData,headers,"户阀管理")
        }
      }).catch((res)=>{

      })

    }
  }

}
</script>

<style scoped>

</style>