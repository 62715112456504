import home from "../view/home";//后台管理
import login from "../view/login";//登录
import main from "../view/main";//首页
import heatingMonitoring from "../view/heatingMonitoring";//供热监控
import basicConfig from "../view/basicConfig";//基础配置

import ioTDevices from "../view/ioTDevices/index";//物联网设备
import ioTDevicesPoint from "../view/ioTDevices/ioTDevicesPoint";//物联网设备

import drawPipeline from "../view/drawPipeline/index";//管线
import drawLine from "../view/drawPipeline/drawPipeline";//画管线
import line_pie from "../view/line";
import pipeNetwork from "../view/pipeNetwork";//管网
import report from "../view/report/index";//报表
import heatingReport from "../view/heatingReport";//报表

import unitValve from "../view/unitValve/index";//单元阀
import houseIndex from "../view/householdValve/index";//户阀
import house from "../view/householdValve/house";//户阀

import smartHeatingMonitoringSystem from "../view/smartHeatingMonitoringSystem";//智慧供热监控系统

import screenConfig from "../view/screen/index";//大屏
import preview from "../view/screen/preview";//预览

import historicalData from "../view/historicalData";//历史数据

import threeMode1_2 from "../view/beng/threeMode1_2";//3d
import threeMode1_3 from "../view/beng/threeMode1_3";//3d
import threeMode2_2 from "../view/beng/threeMode2_2";//3d
import threeMode2_3 from "../view/beng/threeMode2_3";//3d
import threeMode3_2 from "../view/beng/threeMode3_2";//3d
import threeMode3_3 from "../view/beng/threeMode3_3";//3d
import threeMode4_2 from "../view/beng/threeMode4_2";//3d
import threeMode4_3 from "../view/beng/threeMode4_3";//3d
import monitor_3d from "../view/beng/monitor_3d";//3d

import bindHeatStations from "../view/bindHeatStations";//小区绑换热站
import unitValveManagement  from "../view/unitValveManagement";//单元阀管理
import houseValveManagement  from "../view/houseValveManagement";//户阀管理



var routes = [
    {name:"login",path:'/login',component: login},
    {name:"home",path:'/home',component:home,redirect:"/basicConfig",children:[
            {name:"basicConfig",path:"/basicConfig",component:basicConfig},
            {name:"ioTDevices",path:"/ioTDevices",component:ioTDevices},
            {name:"drawPipeline",path:"/drawPipeline",component:drawPipeline},
            {name:"report",path:"/report",component:report},
            {name:"bindHeatStations",path:"/bindHeatStations",component:bindHeatStations},
            {name:"unitValveManagement",path:"/unitValveManagement",component:unitValveManagement},
            {name:"houseValveManagement",path:"/houseValveManagement",component:houseValveManagement},
        ]},
    {name:"main",path:'',component:main},
    {name:"heatingMonitoring",path:'/heatingMonitoring',component:heatingMonitoring},
    {name:"line_pie",path:"/line_pie",component:line_pie},
    {name:"pipeNetwork",path:"/pipeNetwork",component:pipeNetwork},
    {name:"heatingReport",path:"/heatingReport",component:heatingReport},
    {name:"unitValve",path:"/unitValve",component:unitValve},
    {name:"houseIndex",path:"/houseIndex",component:houseIndex},
    {name:"house",path:"/house",component:house},
    {name:"drawLine",path:"/drawLine",component:drawLine},
    {name:"ioTDevicesPoint",path:"/ioTDevicesPoint",component:ioTDevicesPoint},
    {name:"smartHeatingMonitoringSystem",path:"/smartHeatingMonitoringSystem",component:smartHeatingMonitoringSystem},
    {name:"preview",path:"/preview",component:preview},
    {name:"screenConfig",path:"/screenConfig",component:screenConfig},
    {name:"historicalData",path:"/historicalData",component:historicalData},
    {name:"threeMode1_2",path:"/threeMode1_2",component:threeMode1_2},
    {name:"threeMode1_3",path:"/threeMode1_3",component:threeMode1_3},
    {name:"threeMode2_2",path:"/threeMode2_2",component:threeMode2_2},
    {name:"threeMode2_3",path:"/threeMode2_3",component:threeMode2_3},
    {name:"threeMode3_2",path:"/threeMode3_2",component:threeMode3_2},
    {name:"threeMode3_3",path:"/threeMode3_3",component:threeMode3_3},
    {name:"threeMode4_2",path:"/threeMode4_2",component:threeMode4_2},
    {name:"threeMode4_3",path:"/threeMode4_3",component:threeMode4_3},
    {name:"monitor_3d",path:"/monitor_3d",component:monitor_3d},


]
export default routes