<template>
<div class="main_page">
  <main-header :title="title"></main-header>
  <div class="header_time" style="right: 3rem">
    <i class="iconfont icon-rili"></i>
    <span>{{time}}</span>
  </div>
  <homeBtn :show_back="false"></homeBtn>
  <div class="reportContainer">
    <div class="selectCont">
      <div class="searchItem m_l_1">
        <span class="searchTit"></span>
        <a-select
            v-model:value="dp_id"
            placeholder="选择"
            style="width: 200px">
          <a-select-option value="" >全部</a-select-option>
          <a-select-option :value="item.device_model_id" v-for="(item,index) in station_data" :key="index">{{ item.dp_name}}</a-select-option>
        </a-select>
      </div>
      <div class="searchItem m_l_1">
        <span class="searchTit"></span>
        <a-input v-model:value="keyword" autofocus placeholder="输入关键字"/>
      </div>
      <div class="searchItem m_l_1" >
        <div class="def_btn_1" @click="search" style="color: #ffffff">查询</div>
      </div>
      <div class="searchItem m_l_1" style="position: absolute;right: 20px">
        <div class="def_btn_1" @click="backFun" style="color: #ffffff">返回</div>
      </div>


    </div>
    <div class="tableCont" id="tableCont">
      <table class="customTable" id="headTable" style="position: fixed;top: 1.68rem;z-index:1">
        <thead>
        <tr>
          <th rowspan="2" style="width: 60px">编号</th>
          <th rowspan="2" style="width: 140px">用户名称</th>
          <th rowspan="2">分区</th>
          <th v-if="station_header_data.oneNetwork.length" :colspan="station_header_data.oneNetwork.length">一次网参数</th>
          <th rowspan="2" v-if="station_header_data.valvePos.length">{{station_header_data.valvePos[0].name}} {{station_header_data.valvePos[0].unit?("("+station_header_data.valvePos[0].unit+")"):""}}</th>
          <th v-if="station_header_data.secNetwork.length" :colspan="station_header_data.secNetwork.length">二次网参数</th>
          <th rowspan="2">供暖面积</th>
          <th rowspan="2">采暖方式</th>
          <th rowspan="2" v-for="(item,index) in station_header_data.extData" :key="index">{{item}}</th>
          <th rowspan="2">控制</th>
          <th rowspan="2">流程图</th>
          <th rowspan="2">历史数据</th>
        </tr>
        <tr>
          <template v-for="(item,index) in station_header_data.oneNetwork" :key="index">
            <th>{{ item.name }} {{item.unit?("("+item.unit+")"):""}}</th>
          </template>
          <template v-for="(item,index) in station_header_data.secNetwork" :key="index">
            <th>{{ item.name }} {{item.unit?("("+item.unit+")"):""}}</th>
          </template>
        </tr>
        </thead>
      </table>
      <table class="customTable" id="tableData" style="margin-bottom: 20px">
        <thead>
        <tr>
          <th rowspan="2" style="width: 60px">编号</th>
          <th rowspan="2" style="width: 140px">用户名称</th>
          <th rowspan="2">分区</th>
          <th v-if="station_header_data.oneNetwork.length" :colspan="station_header_data.oneNetwork.length">一次网参数</th>
          <th rowspan="2" v-if="station_header_data.valvePos.length">{{station_header_data.valvePos[0].name}} {{station_header_data.valvePos[0].unit?("("+station_header_data.valvePos[0].unit+")"):""}}</th>
          <th v-if="station_header_data.secNetwork.length" :colspan="station_header_data.secNetwork.length">二次网参数</th>
          <th rowspan="2">供暖面积</th>
          <th rowspan="2">采暖方式</th>
          <th rowspan="2" v-for="(item,index) in station_header_data.extData" :key="index">{{item}}</th>
          <th rowspan="2">控制</th>
          <th rowspan="2">流程图</th>
          <th rowspan="2">历史数据</th>
        </tr>
        <tr>
          <template v-for="(item,index) in station_header_data.oneNetwork" :key="index">
            <th>{{ item.name }} {{item.unit?("("+item.unit+")"):""}}</th>
          </template>
          <template v-for="(item,index) in station_header_data.secNetwork" :key="index">
            <th>{{ item.name }} {{item.unit?("("+item.unit+")"):""}}</th>
          </template>
        </tr>
        </thead>
        <tbody>
        <template v-for="(item,index) in deviceList" :key="index" >
          <tr v-for="(val,key) in item.fenqu" :key="key" v-show="item.device_name.includes(keyword2)&&item.device_model_id.includes(dp_id2)">
            <td style="text-align: center" :rowspan="item.fenqu.length?item.fenqu.length:1" v-if="key ==0">{{ index+1 }}</td>
            <td style="text-align: center" :rowspan="item.fenqu.length?item.fenqu.length:1" v-if="key ==0">{{item.device_name}}</td>
            <td style="text-align: center" >{{ val }}</td>
<!--            一次网-->
            <template v-if="key ==0">
              <td style="text-align: center" :rowspan="item.fenqu?item.fenqu.length:1"  v-for="(v,k) in station_header_data.oneNetwork" :key="k">
                <span v-if="item.oneNetwork[k].type=='计算'">{{ item.oneNetwork[k].calc[key] }}</span>
                <span v-else>{{ item.oneNetwork[k].pp_id[key] }}</span>
              </td>
            </template>
            <!--            阀位-->
            <template v-for="(v,k) in station_header_data.valvePos" :key="k">
              <td style="text-align: center" :rowspan="item.fenqu?item.fenqu.length:1" v-if="key ==0">
                <span v-if="item.valvePos[k].type=='计算'">{{ item.valvePos[k].calc[key] }}</span>
                <span v-else>{{ item.valvePos[k].pp_id[key] }}</span>
              </td>
            </template>
<!--            二次网-->
            <template v-for="(v,k) in station_header_data.secNetwork" :key="k">
              <td style="text-align: center">
               <span v-if="item.secNetwork[k].type=='计算'">{{item.secNetwork[k].calc[key]}}</span>
               <span v-else>{{item.secNetwork[k].pp_id[key]}}</span>
              </td>
            </template>
            <td style="text-align: center" :rowspan="item.fenqu.length?item.fenqu.length:1" v-if="key ==0">{{item.ext.gnmj}}</td>
            <td style="text-align: center" :rowspan="item.fenqu.length?item.fenqu.length:1" v-if="key ==0">{{item.ext.cnfs}}</td>
            <!--           扩展属性 -->
            <template v-for="(v,k) in station_header_data.extData" :key="k">
              <td style="text-align: center" :rowspan="item.fenqu?item.fenqu.length:1" v-if="key ==0">
                {{item.extData[v]?item.extData[v]:""}}
              </td>
            </template>
            <td style="text-align: center" :rowspan="item.fenqu.length?item.fenqu.length:1" v-if="key ==0">
              <a-button type="link" @click="showControl(item,index)">控制</a-button>
            </td>
            <td style="text-align: center" :rowspan="item.fenqu.length?item.fenqu.length:1" v-if="key ==0"><a-button type="link" @click="toLCT(item,index)">查看</a-button></td>
            <td style="text-align: center" :rowspan="item.fenqu.length?item.fenqu.length:1" v-if="key ==0"><a-button type="link" @click="toHistory(item,index)">查询</a-button></td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>
import $ from "jquery"
export default {
  name: "heatingReport",
  data(){
    return {
      title:"换热站管理",
      time:"",
      keyword:"",
      id:"",
      reportData:{},
      station_header_data:{
        dp_ids:[],
        lct:"1",
        czjb:"0",
        oneNetwork:[],
        secNetwork:[],
        valvePos:[]
      },
      station_data:[],
      dpList:[],
      wlwDevList:[],
      deviceList:[],
      deviceObj:[],
      num:10,
      realData:{},
      extInfo:{},
      dp_id:"",
      keyword2:"",
      dp_id2:"",
      timer:null,
      device_ext_obj:{}
    }
  },
  mounted(){
    this.$axios.defaults.headers.common["Authorization"] = "bearer "+this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.id = this.$route.query.id;
    this.title = this.$route.query.name;
    this.time = this.$getTime(new Date()).t3;
    setInterval(()=>{
      this.time = this.$getTime(new Date()).t3;
    },1000)
    this.getDPList()
    var _this = this;
    $('#tableCont').scroll(function(){
     _this.setWidth()
    })
  },
  methods:{
    toHome(){
      var url = this.$router.resolve({
        name:"home",
        query:{
          key:this.$route.query.key
        }
      })
      window.open(url.href,"_blank")
    },
    //获取表格信息
    getReportData(){
      var url = "/heating/station-report/info?keyword="+this.title;
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          var pp_ids = [];
          this.reportData = res.data[0];
          this.station_header_data = this.reportData.station_header_data?JSON.parse(this.reportData.station_header_data):{
            dp_ids:[],
            lct:"1",
            czjb:"0",
            oneNetwork:[],
            secNetwork:[],
            valvePos:[],
            extData:[]
          }
          this.station_header_data.extData = this.station_header_data.extData?this.station_header_data.extData:[];
          this.station_data = this.reportData.station_data?JSON.parse(this.reportData.station_data):[];

          var save_dp_List = [];
          this.deviceList = []
          this.station_header_data.dp_ids.forEach((item)=>{
            this.wlwDevList.some((val,key)=>{
              if(item == val.dp_id){
                var oneNetwork = JSON.parse(JSON.stringify(this.station_header_data.oneNetwork));
                var secNetwork = JSON.parse(JSON.stringify(this.station_header_data.secNetwork));
                var valvePos = JSON.parse(JSON.stringify(this.station_header_data.valvePos));
                oneNetwork.forEach((v,k)=>{
                  if(v.type=="计算"){
                    v.calc = this.getCalcFun(val,k,1);
                  }else{
                    v.pp_id=this.getPP_id(val,k,1)
                    pp_ids = pp_ids.concat(v.pp_id)
                  }
                })
                secNetwork.forEach((v,k)=>{
                  if(v.type == "计算"){
                    v.calc = this.getCalcFun(val,k,2);
                  }else{
                    v.pp_id=this.getPP_id(val,k,2)
                    pp_ids = pp_ids.concat(v.pp_id)
                  }
                })
                valvePos.forEach((v,k)=>{
                  if(v.type == "计算"){
                    v.calc = this.getCalcFun(val,k,3);
                  }else{
                    v.pp_id=this.getPP_id(val,k,3)
                    pp_ids = pp_ids.concat(v.pp_id)
                  }
                })
                var obj = {
                  device_model_id:val.dp_id,
                  dp_name:val.dp_name,
                  oneNetwork:JSON.parse(JSON.stringify(oneNetwork)),
                  secNetwork:JSON.parse(JSON.stringify(secNetwork)),
                  valvePos:JSON.parse(JSON.stringify(valvePos)),
                  fenqu:val.fenqu,
                  device_model_context:val.device_model_context
                }
                save_dp_List.push(obj);
                this.getExtData(val.dp_id,key)
                return true
              }
            })
          })
          this.station_data = save_dp_List;//型号表头+内容
          this.$forceUpdate();
          for(var i = 0;i<pp_ids.length;i++){
            if(!pp_ids[i]){
              pp_ids.splice(i,1);
              i--
            }
          }
          this.getPPList(pp_ids);
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    getCalcFun(obj,INDEX,type){
      var calc = [];
      this.station_data.some((item)=>{
        if(item.device_model_id == obj.dp_id){
          if(type==1){//一次网
            calc = item.oneNetwork[INDEX]?item.oneNetwork[INDEX].calc:[];
          }
          if(type==2){//二次网
            calc = new Array(obj.fenqu.length)
            if(item.secNetwork[INDEX]){
              item.secNetwork[INDEX].calc.forEach((val,key)=>{
                if(key<calc.length){
                  calc[key] = val
                }
              })
            }
          }
          if(type==3){//阀位
            calc = item.valvePos[INDEX]?item.valvePos[INDEX].calc:[];
          }
          return true
        }
      })
      return calc
    },
    getPP_id(obj,INDEX,type){
      var pp_id = [];
      this.station_data.some((item,index)=>{
        if(item.device_model_id == obj.dp_id){
          if(type==1){//一次网
            pp_id = item.oneNetwork[INDEX]?item.oneNetwork[INDEX].pp_id:[];
          }
          if(type==2){//二次网
            pp_id = new Array(obj.fenqu.length)
            if(item.secNetwork[INDEX]){
              item.secNetwork[INDEX].pp_id.forEach((val,key)=>{
                if(key<pp_id.length){
                  pp_id[key] = val
                }
              })
            }
          }
          if(type==3){//阀位
            pp_id = item.oneNetwork[INDEX]?item.oneNetwork[INDEX].pp_id:[];
          }
          return true
        }
      })
      return pp_id
    },
    //获取型号列表
    getDPList(){
      var url = "/heating/zeiot/device-protocols-options";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.dpList = res.data;
          this.getWLWDev();
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //获取物联网设备
    getWLWDev(){
      var url = "/heating/device-model/easy-info/part";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.wlwDevList = res.data;
          this.wlwDevList.forEach((item,index)=>{
            item.fenqu = [];
            item.parts.forEach((val)=>{
              item.fenqu.push(val.device_model_partition_name)
            })
            item.device_model_context = item.device_model_context?JSON.parse(item.device_model_context):{
              nrmj:"",
              cnfs:""
            }
            this.dpList.some((val)=>{
              if(item.device_model_id == val.dp_id){
                item = Object.assign(item,val);
                return true
              }
            })
          })
          this.getReportData();
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //控制
    showControl(){

    },
    //流程图
    toLCT(obj,index){
      if(obj.lct == 2){
        var url = this.$router.resolve({
          name:"smartHeatingMonitoringSystem",
          query:{
            key:this.$route.query.key,
            name:obj.device_name,
            id:obj.device_id,
            dp_id:obj.device_model_id
          }
        })
        window.open(url.href,"_blank");
      }else{
        this.getDeviceInfo(obj.device_id)
      }
    },
    //历史数据
    toHistory(obj,index){
      var url = this.$router.resolve({
        name:"historicalData",
        query:{
          key:this.$route.query.key,
          name:obj.device_name,
          id:obj.device_id,
          report_name:this.title,
          dp_id:obj.device_model_id
        }
      })
      window.open(url.href,"_blank");
    },
    //获取设备参数
    getPPList(pp_ids){
      var url = "/heating/zeiot/heat/device/info";
      this.$axios.post(url,pp_ids).then((res)=>{
          if(res.status == 200){
            this.deviceList = [];
            this.realData = {};
            var deviceObj = {};//型号参数
            var device_ids = []
            res.data.forEach((item)=>{//参数列表
              if(!deviceObj.hasOwnProperty(item.device_id)&&this.station_header_data.dp_ids.includes(item.device_dp_id)){
                device_ids.push(item.device_id)
                deviceObj[item.device_id] = [];
              }
              if(deviceObj[item.device_id]){
                deviceObj[item.device_id].push(item);
              }
            })
            //console.log(device_ids)
            this.deviceObj = deviceObj;
            var keys = Object.keys(deviceObj);
            clearInterval(this.timer);
            var ids = device_ids.slice(0,this.num);
            this.getRealData(ids,device_ids,this.num);
            this.timer = setInterval(()=>{
              var ids = device_ids.slice(0,this.num);
              this.getRealData(ids,device_ids,this.num);
            },30000)
          }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //获取设备的实时数据
    getRealData(device_ids,deviceIds,num){
      var url = "/heating/zeiot/device/read-time/all";
      this.$axios.post(url,device_ids).then((res)=>{
        if(res.status == 200){
          var data = res.data;
          this.realData = Object.assign(this.realData,data);
          device_ids.forEach((item)=>{
            if(this.deviceObj[item]&&data[item]){
              this.deviceObj[item].forEach((val)=>{
               data[item].some((v)=>{
                  if(val.pp_id == v.id){
                    val.pp_value = v.value;
                    return true
                  }
                })
              })
            }
            this.station_data.some((val)=>{
              if(val.device_model_id == this.deviceObj[item][0].device_dp_id){
                var ext_data = this.extInfo[val.device_model_id]?this.extInfo[val.device_model_id]:[];
                var ext = {
                  gnmj:0,
                  cnfs:'',
                  xqmj:0,
                  lldh:"",
                  beng_type:"",
                  video_num:1,
                  videos:["","","",""]
                }
                ext_data.forEach((v)=>{
                  if(v.device_attribute_id == item){
                    ext = v.device_attribute_data?JSON.parse(v.device_attribute_data):ext
                  }
                })
                var has_dev = this.deviceList.some((v,k)=>{
                  if(v.device_id == item){
                    var dev_val = JSON.parse(JSON.stringify(val));
                    dev_val.oneNetwork.forEach((v)=>{
                      if(v.type == "计算"){
                        v.calc.forEach((m,mi)=>{
                          v.calc[mi] = "";
                          if(m){
                            var evalStr = m;
                            this.realData[item].forEach(function(vv){
                              if(evalStr.includes(vv.name)){
                                evalStr = evalStr.replace(eval("/"+vv.name+"/g"),parseFloat(vv.value));
                                return true
                              }
                            });
                            if(this.device_ext_obj[item]){
                              this.device_ext_obj[item].forEach(function(vv){
                                if(evalStr.includes(vv.header_key)){
                                  evalStr = evalStr.replace(eval("/"+vv.header_key+"/g"),parseFloat(vv.value));
                                  return true
                                }
                              });
                            }
                            var reg = new RegExp("[\\u4E00-\\u9FFF]+","g")
                            if(!reg.test(evalStr)){
                              v.calc[mi] = eval(evalStr).toFixed(2)
                            }
                          }

                        })
                      }else{
                        v.pp_id.forEach((m,mi)=>{
                          var has_pp = this.deviceObj[item].some((n)=>{
                            if(n.dpp_id == m ){
                              v.pp_id[mi] = n.pp_value;
                              return true
                            }
                          })
                          if(!has_pp){
                            v.pp_id[mi] = "";
                          }
                        })
                      }
                    })
                    dev_val.secNetwork.forEach((v)=>{
                      if(v.type == "计算"){
                        v.calc.forEach((m,mi)=>{
                          v.calc[mi] = "";
                          if(m){
                            var evalStr = m;
                            this.realData[item].forEach(function(vv){
                              if(evalStr.includes(vv.name)){
                                evalStr = evalStr.replace(eval("/"+vv.name+"/g"),parseFloat(vv.value));
                                return true
                              }
                            });
                            if(this.device_ext_obj[item]){
                              this.device_ext_obj[item].forEach(function(vv){
                                if(evalStr.includes(vv.header_key)){
                                  evalStr = evalStr.replace(eval("/"+vv.header_key+"/g"),parseFloat(vv.value));
                                  return true
                                }
                              });
                            }
                            var reg = new RegExp("[\\u4E00-\\u9FFF]+","g")
                            if(!reg.test(evalStr)){
                              v.calc[mi] = eval(evalStr).toFixed(2)
                            }
                          }
                        })
                      }else{
                        v.pp_id.forEach((m,mi)=>{
                          var has_pp = this.deviceObj[item].some((n)=>{
                            if(n.dpp_id == m ){
                              v.pp_id[mi] = n.pp_value;
                              return true
                            }
                          })
                          if(!has_pp){
                            v.pp_id[mi] = "";
                          }
                        })
                      }
                    })
                    dev_val.valvePos.forEach((v)=>{
                      if(v.type == "计算"){
                        v.calc.forEach((m,mi)=>{
                          v.calc[mi] = "";
                          if(m){
                            var evalStr = m;
                            this.realData[item].forEach(function(vv){
                              if(evalStr.includes(vv.name)){
                                evalStr = evalStr.replace(eval("/"+vv.name+"/g"),parseFloat(vv.value));
                                return true
                              }
                            });
                            if(this.device_ext_obj[item]){
                              this.device_ext_obj[item].forEach(function(vv){
                                if(evalStr.includes(vv.header_key)){
                                  evalStr = evalStr.replace(eval("/"+vv.header_key+"/g"),parseFloat(vv.value));
                                  return true
                                }
                              });
                            }
                            var reg = new RegExp("[\\u4E00-\\u9FFF]+","g")
                            if(!reg.test(evalStr)){
                              v.calc[mi] = eval(evalStr).toFixed(2)
                            }
                          }
                        })
                      }else{
                        v.pp_id.forEach((m,mi)=>{
                          var has_pp = this.deviceObj[item].some((n)=>{
                            if(n.dpp_id == m ){
                              v.pp_id[mi] = n.pp_value;
                              return true
                            }
                          })
                          if(!has_pp){
                            v.pp_id[mi] = "";
                          }
                        })
                      }
                    })
                    this.deviceList[k] = Object.assign(this.deviceList[k],dev_val);
                    return true
                  }
                })
                if(!has_dev){
                  this.deviceList.push(Object.assign({},{
                    device_id:item,
                    device_name:this.deviceObj[item][0].device_name,
                    lct:this.station_header_data.lct,
                    extData:{},
                    ext:ext}))
                  this.getDevExt(item,()=>{
                    var dev_val = JSON.parse(JSON.stringify(val));
                    dev_val.oneNetwork.forEach((v)=>{
                      if(v.type == "计算"){
                        v.calc.forEach((m,mi)=>{
                          v.calc[mi] = "";
                          var evalStr = m;
                          this.realData[item].forEach(function(vv){
                            if(evalStr.includes(vv.name)){
                              evalStr = evalStr.replace(eval("/"+vv.name+"/g"),parseFloat(vv.value));
                              return true
                            }
                          });
                          if(this.device_ext_obj[item]){
                            this.device_ext_obj[item].forEach(function(vv){
                              if(evalStr.includes(vv.header_key)){
                                evalStr = evalStr.replace(eval("/"+vv.header_key+"/g"),parseFloat(vv.value));
                                return true
                              }
                            });
                          }
                          var reg = new RegExp("[\\u4E00-\\u9FFF]+","g")
                          if(!reg.test(evalStr)){
                            v.calc[mi] = eval(evalStr).toFixed(2)
                          }
                        })
                      }else{
                        v.pp_id.forEach((m,mi)=>{
                          var has_pp = this.deviceObj[item].some((n)=>{
                            if(n.dpp_id == m ){
                              v.pp_id[mi] = n.pp_value;
                              return true
                            }
                          })
                          if(!has_pp){
                            v.pp_id[mi] = "";
                          }
                        })
                      }
                    })
                    dev_val.secNetwork.forEach((v)=>{
                      if(v.type == "计算"){
                        v.calc.forEach((m,mi)=>{
                          v.calc[mi] = "";
                          var evalStr = m;
                          if(evalStr){
                            this.realData[item].forEach(function(vv){
                              if(evalStr.includes(vv.name)){
                                evalStr = evalStr.replace(eval("/"+vv.name+"/g"),parseFloat(vv.value));
                                return true
                              }
                            });
                            if(this.device_ext_obj[item]){
                              this.device_ext_obj[item].forEach(function(vv){
                                if(evalStr.includes(vv.header_key)){
                                  evalStr = evalStr.replace(eval("/"+vv.header_key+"/g"),parseFloat(vv.value));
                                  return true
                                }
                              });
                            }
                            var reg = new RegExp("[\\u4E00-\\u9FFF]+","g")
                            if(!reg.test(evalStr)){
                              v.calc[mi] = eval(evalStr).toFixed(2)
                            }
                          }
                        })
                      }else{
                        v.pp_id.forEach((m,mi)=>{
                          var has_pp = this.deviceObj[item].some((n)=>{
                            if(n.dpp_id == m ){
                              v.pp_id[mi] = n.pp_value;
                              return true
                            }
                          })
                          if(!has_pp){
                            v.pp_id[mi] = "";
                          }
                        })
                      }
                    })
                    dev_val.valvePos.forEach((v)=>{
                      if(v.type == "计算"){
                        v.calc.forEach((m,mi)=>{
                          v.calc[mi] = "";
                          var evalStr = m;
                          this.realData[item].forEach(function(vv){
                            if(evalStr.includes(vv.name)){
                              evalStr = evalStr.replace(eval("/"+vv.name+"/g"),parseFloat(vv.value));
                              return true
                            }
                          });
                          if(this.device_ext_obj[item]){
                            this.device_ext_obj[item].forEach(function(vv){
                              if(evalStr.includes(vv.header_key)){
                                evalStr = evalStr.replace(eval("/"+vv.header_key+"/g"),parseFloat(vv.value));
                                return true
                              }
                            });
                          }
                          var reg = new RegExp("[\\u4E00-\\u9FFF]+","g")
                          if(!reg.test(evalStr)){
                            v.calc[mi] = eval(evalStr).toFixed(2)
                          }
                        })
                      }else{
                        v.pp_id.forEach((m,mi)=>{
                          var has_pp = this.deviceObj[item].some((n)=>{
                            if(n.dpp_id == m ){
                              v.pp_id[mi] = n.pp_value;
                              return true
                            }
                          })
                          if(!has_pp){
                            v.pp_id[mi] = "";
                          }
                        })
                      }
                    })
                    this.deviceList.some((v,k)=>{
                      if(v.device_id == item){
                        this.deviceList[k] = Object.assign(this.deviceList[k],dev_val)
                        return true
                      }
                    })
                  });
                }
                return true
              }
            })
          })
          var ids = deviceIds.slice(num,num+this.num);
          if(ids.length>0){
            this.getRealData(ids,deviceIds,num+this.num);
          }
        }
        setTimeout(()=>{
          this.setWidth()
        },500)
      }).catch((res)=>{
        console.log(res);
      })
    },
    setWidth(){
      $('#headTable').width($("#tableData").width());
      var td1 = $("#tableData").find("thead tr:first-child th");
      var td2 = $("#headTable").find("thead tr:first-child th");
      td2.each((k,v)=>{
        $(v).width($(td1[k]).width());
      })
    },
    //获取设备扩展属性
    getExtData(dp_id,KEY){
      var url = "/heating/device-attribute/info?dp_id="+dp_id;
      this.$axios.get(url).then((res)=>{
        this.extInfo[dp_id] = res.data;
      }).catch((res)=>{
        console.log(res);
      })
    },
    //获取设备信息
    getDeviceInfo(device_id){
      var url = "/api/user/devices/"+device_id;
      this.$getZeiotApi(url,"get","",(res)=>{
        if(res.status == 200){
          if(res.data.device_monitor_id){
            var ip = this.$axios_ip.defaults.baseURL;
            window.open(ip+'/template/project/monitoring-list/simulated_scene.html?monitor_id=' + res.data.device_monitor_id + '&system_id=' + this.$cookie.get("user_system_id") + '&project_id=' + this.$cookie.get("project_id") + '&salt=' + this.$cookie.get("user_salt") + '&MenuId=&user_id=' + this.$cookie.get("user_id"),"_blank")
          }else{

          }

        }
      })
    },
    //返回
    backFun(){
      this.$router.back();
    },
    filterDp(e,option){
      return option.dp_name.includes(e);
    },
    search(){
      this.keyword2 = this.keyword;
      this.dp_id2 = this.dp_id;

    },
    //获取设备的扩展属性 设备所属型号 绑定的扩展属性
    getDevColumn(device_id,fun){
      var url = "/api/sys/extra-table/type/device/"+device_id+"/column";
      this.$getZeiotApi(url,"GET","",(res)=>{
        if(fun){
          fun(res.data)
        }
      })
    },
    //获取设备的扩展属性
    getDevExt(device_id,fun){
      this.getDevColumn(device_id,(column)=>{
        var url = "/api/user/devices/"+device_id;
        this.$getZeiotApi(url,"GET","",(res)=>{
          if(res.status == 200){
            var extra_data = []
            if(res.data.extra_data){
              var items = JSON.parse(res.data.extra_data).items;
              column.forEach((item)=>{
                var has_c = items.some((val)=>{
                  if(item.etc_column_name == val.key){
                    val.header_key = item.etc_column_header;
                    extra_data.push(val);
                    return true
                  }
                })
                if(!has_c){
                  extra_data.push({
                    key:item.etc_column_name,
                    value:item.etc_column_default_value,
                    header_key:item.etc_column_header
                  })
                }
              })
            }
            this.device_ext_obj[device_id] = extra_data;
            var obj = {};
            this.station_header_data.extData.forEach((item=>{
              obj[item] = "";
              extra_data.some((val)=>{
                if(val.header_key ==item){
                  obj[item] = val.value;
                  return true
                }
              })
            }))
            this.deviceList.some((item)=>{
              if(item.device_id == device_id){
                item.extData = obj;
                return true
              }
            });
            if(fun){
              fun()
            }
          }
        })
      })
    }

  }
}

</script>

<style scoped>
.main_page{
  background: #0a143f;
}

</style>