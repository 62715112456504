<template>
  <div class="valvePage">
    <valve-header :title="title"></valve-header>
    <div class="valveHomeBtn" @click="valveHomeBtn">首页</div>
    <div class="valveHeaderRight">
      <div class="weather">
        <iframe width="210" scrolling="no" height="25" frameborder="0" allowtransparency="true" src="https://i.tianqi.com?c=code&id=26&color=%23FFFFFF&icon=1&site=12"></iframe>
      </div>
      <div class="time" style="color: #57adfb;">
        <div>{{ time1 }}</div>
        <div>{{ time2 }}</div>
      </div>
    </div>
    <div class="valveContainer">
      <div class="valveLeft">
        <div class="org_searchBox">
          <input type="text" placeholder="请输入关键字搜索" autocomplete="off" v-model="keyword">
        </div>
        <div class="orgCont">
          <ul class="orgList">
            <li v-for="(item,index) in xqList" :key="index" @click="chooseXQ(index)" v-show="item.community_name.includes(keyword)">
              <p :class='{"active":(xiaoqu == item.community_id)}'>
                <span>{{ item.community_name }}</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="valveRight">
        <div class="build_card" v-for="(item,index) in ldList" :key="index" @click="toPage(index)">
         <div class="build_c">
           <img src="../../assets/images/valve/unit.png">
           <div>{{ item.building_name }}</div>
         </div>
          <div class="bg_card"></div>
        </div>


      </div>
    </div>
</div>
</template>

<script>
export default {
  name: "index",
  data(){
    return {
      title:"户阀管理系统",
      xqList:[],
      xiaoqu:"",
      xiaoqu_name:"",
      ldList:[],
      keyword:"",
      time1:"",
      time2:""
    }
  },
  mounted(){
    this.$axios.defaults.headers.common["Authorization"] = "bearer "+this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.xiaoqu = this.$route.query.xiaoqu;
    var time = this.$getTime(new Date());
    this.time1 = time.d2;
    this.time2 = time.week+" "+time.t2;
    setInterval(()=>{
      time = this.$getTime(new Date());
      this.time1 = time.d2;
      this.time2 = time.week+" "+time.t2;
    },1000)
    this.getXQList();
  },
  methods:{
    //获取小区列表
    getXQList(){
      var url = "/heating/community/info";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.xqList = res.data;
          if(this.xqList.length>0){
            if(this.xiaoqu){
              this.xqList.some((item)=>{
                if(item.community_id == this.xiaoqu){
                  this.xiaoqu_name = item.community_name;
                  return true
                }
              })
            }else{
              this.xiaoqu = this.xqList[0].community_id;
              this.xiaoqu_name = this.xqList[0].community_name;
            }
            this.getLDList();
          }
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    chooseXQ(index){
      this.xiaoqu = this.xqList[index].community_id;
      this.xiaoqu_name = this.xqList[index].community_name;
      this.getLDList();
    },
    //获取楼栋列表
    getLDList(){
      var url = "/heating/building/info?community_id="+this.xiaoqu;
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.ldList = res.data;
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    toPage(index){
      console.log(index,this.ldList[index])
      this.$router.push({
        name:"house",
        query:{
          key:this.$route.query.key,
          building_id:this.ldList[index].building_id,
          community_id:this.ldList[index].building_community_id,
          building_name:this.ldList[index].building_name,
          community_name:this.xiaoqu_name
        }
      })
    },
    onExpand(){

    },
    search(){

    },
    valveHomeBtn(){
      this.$router.back()

    }
  }
}
</script>

<style scoped lang="less">
@import "../../assets/css/valve.less";

</style>