<template>
<div class="main_page" style="overflow: auto">
  <main-header :title="tit"></main-header>
  <div class="header_time" style="right: 3rem">
    <i class="iconfont icon-rili"></i>
    <span>{{time}}</span>
  </div>
  <homeBtn :show_back="false"></homeBtn>
  <div class="left_btns">
    <div class="def_btn_1" @click="to3D"><swap-outlined />3D模式</div>
  </div>
  <div class="lct_left">
    <div class="lct_card lct_card1 card_bg">
      <div>
        <div>
          <span>用户名称：</span>{{name}}
        </div>
      </div>
      <div>
        <div>
          <span>小区面积：</span>{{  extInfo.device_attribute_data?extInfo.device_attribute_data.xqmj :""}}m²
        </div>
        <div>
          <span>供暖面积：</span>{{ extInfo.device_attribute_data?extInfo.device_attribute_data.gnmj:"" }}m²
        </div>
      </div>
      <div>
        <div>
          <span>采暖方式：</span>{{ extInfo.device_attribute_data?extInfo.device_attribute_data.cnfs:"" }}
        </div>
        <div>
          <span>理论单耗：</span>{{ extInfo.device_attribute_data?extInfo.device_attribute_data.lldh:"" }}
        </div>
      </div>

    </div>
    <div class="lct_card lct_card2">
      <div>
        <div class="color_1">{{ zydl_value }}</div>
        <div>累计用电（KWH）</div>
      </div>
      <div>
        <div class="color_2">{{ ljll_value }}</div>
        <div>累计用水（T）</div>
      </div>
      <div>
        <div class="color_3">{{ nh_24 }}</div>
        <div>24小时能耗</div>
      </div>
      <div>
        <div class="color_4">{{ nh_avg_1 }}</div>
        <div>本月平均能耗</div>
      </div>
    </div>
    <div class="lct_card lct_card3 m_t_1">
      <div class="lct_card_tit">能耗曲线</div>
      <div class="lct_card_cont card_bg">
        <div id="echart1" style="width: 100%;height: 100%"></div>
      </div>
    </div>
    <div class="lct_card lct_card4 m_t_1">
      <div class="lct_card_tit">温度曲线</div>
      <div class="lct_card_cont card_bg">
        <div id="echart2" style="width: 100%;height: 100%"></div>
      </div>
    </div>
  </div>
  <div class="lct_center">
    <div class="lct_c_lct">
      <template v-if="extInfo.device_attribute_data">
        <beng1_2 v-if="extInfo.device_attribute_data.beng_type=='1_2'"></beng1_2>
        <beng1_3 v-if="extInfo.device_attribute_data.beng_type=='1_3'"></beng1_3>
        <beng2_2 v-if="extInfo.device_attribute_data.beng_type=='2_2'"></beng2_2>
        <beng2_3 v-if="extInfo.device_attribute_data.beng_type=='2_3'"></beng2_3>
        <beng3_2 v-if="extInfo.device_attribute_data.beng_type=='3_2'"></beng3_2>
        <beng3_3 v-if="extInfo.device_attribute_data.beng_type=='3_3'"></beng3_3>
        <beng4_2 v-if="extInfo.device_attribute_data.beng_type=='4_2'"></beng4_2>
        <beng4_3 v-if="extInfo.device_attribute_data.beng_type=='4_3'"></beng4_3>
        <div class="beng_cont" v-if="extInfo.device_attribute_data.beng_type=='monitor_template'">
          <iframe frameborder="none" scrolling="none" style="width: 100%;height: 100%"  :src="monitor_2d_url"></iframe>
        </div>
        <div class="beng_cont" v-if="extInfo.device_attribute_data.beng_type=='monitor_other'">
          <iframe frameborder="none" scrolling="none" style="width: 100%;height: 100%"  :src="monitor_2d_url"></iframe>
        </div>
      </template>
    </div>
    <div class="lct_c_b">
      <div class="lct_b_card1 card_bg">
        <div>
          <div class="e_tit">一次供水压力</div>
          <div style="width: 100%;height:0.7rem " id="echart3"></div>
          <div class="circle">{{gsyl_value}}Mpa</div>
        </div>
        <div>
          <div class="e_tit">一次供水温度</div>
          <div style="width: 100%;height:0.7rem " id="echart5"></div>
          <div class="tep1">{{wdgw_value}}℃</div>
        </div>

        <div>
          <div >一次回水压力</div>
          <div style="width: 100%;height:0.7rem " id="echart4"></div>
          <div class="circle circle2">{{hsyl_value}}Mpa</div>
        </div>
        <div>
          <div >一次回水温度</div>
          <div style="width: 100%;height:0.7rem " id="echart6"></div>
          <div class="tep2">{{ylhw_value}}℃</div>
        </div>
      </div>
      <div class="lct_b_card2 card_bg">
        <div>
          <div style="color: #ffffff;padding:0 0.3rem 0.1rem;font-size:0.14rem ">二次网供/回水温度</div>
          <div id="echart7" style="width:100%;height: 1.82rem;"></div>
        </div>
        <div>
          <div style="color: #ffffff;padding:0 0.3rem 0.1rem;font-size:0.14rem ">二次网供/回水压力</div>
          <div id="echart8" style="width:100%;height: 1.82rem;"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="lct_right">
    <div class="lct_card lct_card5">
      <div>
        <div class="color_2">{{ wdCard.swwd1 }}℃</div>
        <div>室外温度</div>
      </div>
      <div>
        <div class="color_2">{{ wdCard.swwd1_xz }}℃</div>
        <div>修正后室外温度</div>
      </div>
      <div>
        <div class="color_2">{{ wdCard.swwd2 }}℃</div>
        <div>24小时后室外温度</div>
      </div>
      <div>
        <div class="color_2">{{ wdCard.swwd2_xz }}℃</div>
        <div>24小时后修正温度</div>
      </div>
      <div>
        <div class="color_2">{{ wdCard.swwd3 }}℃</div>
        <div>48小时后室外温度</div>
      </div>
      <div>
        <div class="color_2">{{ wdCard.swwd3_xz }}℃</div>
        <div>48小时后修正温度</div>
      </div>
    </div>
    <div class="lct_card lct_card6 m_t_1">
      <div class="lct_card_tit">视频监控</div>
      <div class="lct_card_cont card_bg" v-if="extInfo.device_attribute_data">
        <div style="width: 100%;height: 100%" v-if="parseInt(extInfo.device_attribute_data.video_num)==1">
          <div style="width: 100%;height: 100%;">
            <video_c :id="'video_'+1" :src="extInfo.device_attribute_data.videos[0]" v-if="extInfo.device_attribute_data.videos[0]"></video_c>
          </div>
        </div>
        <div style="width: 100%;height: 100%;display: flex" v-if="parseInt(extInfo.device_attribute_data.video_num)==2">
          <template v-for="(count) in parseInt(extInfo.device_attribute_data.video_num)" :key="count">
            <div style="width: 50%;height: 100%;">
              <video_c :id="'video_'+count" :src="extInfo.device_attribute_data.videos[count-1]" v-if="extInfo.device_attribute_data.videos[count-1]"></video_c>
            </div>
          </template>
        </div>
        <div style="width: 100%;height: 100%;display: flex" v-if="parseInt(extInfo.device_attribute_data.video_num)==3">
          <div style="width: 60%;height: 100%;">
            <video_c :id="'video_'+1" :src="extInfo.device_attribute_data.videos[0]" v-if="extInfo.device_attribute_data.videos[0]"></video_c>
          </div>
          <div style="width: 40%;height: 100%">
            <div style="height: 50%;">
              <video_c :id="'video_'+2" :src="extInfo.device_attribute_data.videos[1]" v-if="extInfo.device_attribute_data.videos[1]"></video_c>
            </div>
            <div style="height: 50%;">
              <video_c :id="'video_'+3" :src="extInfo.device_attribute_data.videos[2]" v-if="extInfo.device_attribute_data.videos[2]"></video_c>
            </div>
          </div>
        </div>
        <div style="width: 100%;height: 100%;display: flex;flex-wrap: wrap" v-if="parseInt(extInfo.device_attribute_data.video_num)==4">
          <template v-for="(count) in parseInt(extInfo.device_attribute_data.video_num)" :key="count">
            <div style="width: 50%;height: 50%;">
              <video_c :id="'video_'+count" :src="extInfo.device_attribute_data.videos[count-1]" v-if="extInfo.device_attribute_data.videos[count-1]"></video_c>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="lct_card lct_card7 m_t_1">
      <div class="lct_card_tit">报警信息</div>
      <div class="lct_card_cont card_bg">
        <div class="alarm_card" >
          <div class="alarm_table_head">
            <div>采集值</div>
            <div>发生时间</div>
          </div>
          <div class="alarm_table_body">
            <div class="alarm_table_tr" v-for="(item,index) in alarmList" :key="index" >
              <div :title="item.al_content" :style='{"color":(item.al_stop_time?"#ffffff":"red")}' >{{item.al_content}}</div>
              <div :title="item.al_start_time" >{{item.al_start_time}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lct_card lct_card8 m_t_1 card_bg">
      <div>
        <div>
          <template v-if="unit_valve_num<100">
            <div class="c_1">{{ unit_valve_num>10?parseInt(unit_valve_num/10):0 }}</div>
            <div class="c_1">{{ unit_valve_num%10 }}</div>
          </template>
          <template v-if="unit_valve_num>=100">
            <div class="c_1">{{ parseInt(unit_valve_num/100) }}</div>
            <div class="c_1">{{ parseInt((unit_valve_num%100)/10)}}</div>
            <div class="c_1">{{ (unit_valve_num%100)%10 }}</div>
          </template>
          <small>个</small>
        </div>
        <span @click="toUnitPage">单元阀</span>
      </div>
      <div>
        <div>
          <template v-if="house_valve_num<100">
            <div class="c_1">{{ house_valve_num>10?parseInt(house_valve_num/10):0 }}</div>
            <div class="c_1">{{ house_valve_num%10 }}</div>
          </template>
          <template v-if="house_valve_num>=100">
            <div class="c_1">{{ parseInt(house_valve_num/100) }}</div>
            <div class="c_1">{{ parseInt((house_valve_num%100)/10)}}</div>
            <div class="c_1">{{ (house_valve_num%100)%10 }}</div>
          </template>
          <small>个</small>
        </div>
        <span @click="toHousePage">户阀</span>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import video_c from "../components/video"
import beng1_2 from "./beng/beng1_2"
import beng1_3 from "./beng/beng1_3"
import beng2_2 from "./beng/beng2_2"
import beng2_3 from "./beng/beng2_3"
import beng3_2 from "./beng/beng3_2"
import beng3_3 from "./beng/beng3_3"
import beng4_2 from "./beng/beng4_2"
import beng4_3 from "./beng/beng4_3"
import $ from "jquery"
import * as echarts from "echarts"
var echart1 = null;
var echart2 = null;
var echart3 = null;
var echart4 = null;
var echart5 = null;
var echart6 = null;
var echart7 = null;
var echart8 = null;
export default {
  name: "smartHeatingMonitoringSystem",
  components:{
    beng1_2,
    beng1_3,
    beng2_2,
    beng2_3,
    beng3_2,
    beng3_3,
    beng4_2,
    beng4_3,
    video_c
  },
  props:{
    title:{
      type:String,
      default:"监控系统"
    }
  },
  data(){
    return {
      time:"",
      tit:"",
      device_id:"",
      dp_id:"",
      name:"",
      wdCard:{
        swwd1_name:'温度',//室外温度
        swwd1:0,//室外温度
        swwd1_id:'',//室外温度

        swwd1_xz_name:'实时修正温度',//修正后室外温度
        swwd1_xz:0,//修正后室外温度
        swwd1_xz_id:'',//修正后室外温度

        swwd2_name:'24小时温度',//24小时室外温度
        swwd2:0,//24小时室外温度

        swwd2_xz_name:'24修正温度',//24小时修正后室外温度
        swwd2_xz:0,//24小时修正后室外温度

        swwd3_name:'48小时温度',//48小时室外温度
        swwd3:0,//48小时室外温度

        swwd3_xz_name:'48修正温度',//48小时修正后室外温度
        swwd3_xz:0,//48小时修正后室外温度
      },
      paramsList:[],
      nh_24:0,//24小时能耗
      nh_avg_1:0,//本月平均能耗
      zljrl_id:'',//能耗  一次总累计热量
      zljrl_name:'一次总累计热量',//能耗  一次总累计热量
      
      zydl_id:'',//累计用电  总用电量1
      zydl_name:'总用电量1',//累计用电  总用电量1
      zydl_value:0,//累计用电  总用电量1
      
      ljll_id:'',//累计用水  补水累计流量1
      ljll_name:'补水累计流量1',//累计用水  补水累计流量1
      ljll_value:0,//累计用水  补水累计流量1

      gsyl_id:'',//一次网供水压力  一次供水压力1
      gsyl_name:'一次供水压力1',//一次网供水压力  一次供水压力1
      gsyl_value:0,//一次网供水压力  一次供水压力1
      hsyl_id:'',//一次网回水压力  一次回水压力1
      hsyl_name:'一次回水压力1',//一次网回水压力  一次回水压力1
      hsyl_value:0,//一次网回水压力  一次回水压力1
      wdgw_id:'',//一次网供水温度  温度/供温1_1
      wdgw_name:'温度/供温1_1',//'温度/供温1_1',//一次网供水温度  温度/供温1_1
      wdgw_value:0,//一次网供水温度  温度/供温1_1
      ylhw_id:'',//一次网回水温度  压力/回温1_1
      ylhw_name:'压力/回温1_1',//'压力/回温1_1',//一次网回水温度  压力/回温1_1
      ylhw_value:0,//一次网回水温度  压力/回温1_1


      ecwgs:[],//二次网供水温度 分高区 地区
      ecwhs:[],//二次网回水温度 分高区 地区
      alarmList:[],
      extInfo:{},
      unit_valve_num:0,
      house_valve_num:0,
      monitor_2d_url:"",
      xiaoqu:""
    }
  },
  mounted(){
    this.$axios.defaults.headers.common["Authorization"] = "bearer "+this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.device_id = this.$route.query.id;
    this.dp_id = this.$route.query.dp_id;
    this.name = this.$route.query.name;
    this.tit = this.$route.query.name+"监控系统";
    this.time = this.$getTime(new Date()).t3;
    setInterval(()=>{
      this.time = this.$getTime(new Date()).t3;
    },1000)
    this.drawEchart1()
    this.drawEchart2()
    this.drawEchart3()
    this.drawEchart4()
    this.drawEchart5()
    this.drawEchart6()
    this.drawEchart7()
    this.drawEchart8()


    this.getExtData(this.dp_id)
    this.getParamsList(this.device_id,(obj)=>{
      var ids = [];
      var ids2 = [];
      obj.data.forEach( (item)=>{
        /*if(item.pp_name == this.wdCard.swwd1_name){//室外温度
          this.wdCard.swwd1_id = item.pp_id;
        }
        if(item.name==this.wdCard.swwd1_xz_name){//实时修正温度
          this.wdCard.swwd1_xz_id = item.id;
        }*/
/*        if(item.pp_name == this.wdCard.gsyl_name){//一次供水压力1
          this.wdCard.gsyl_id = item.pp_id;
        }
        if(item.pp_name == this.wdCard.hsyl_name){//一次回水压力1
          this.wdCard.hsyl_id = item.pp_id;
        }
        if(item.pp_name == this.wdCard.wdgw_name){//温度/供温1_1
          this.wdCard.wdgw_id = item.pp_id;
        }
        if(item.pp_name == this.wdCard.ylhw_name){//压力/回温1_1
          this.wdCard.ylhw_id = item.pp_id;
        }*/
        if(item.pp_name == "二次供水温度1"){
          ids.push(item.pp_id);
          this.ecwgs.push({
            name:"二次网供水温度",
            id:item.pp_id,
            qu:"二次网供水温度"
          })
        }
        if(item.pp_name == "二次回水温度1"){
          ids.push(item.pp_id);
          this.ecwgs.push({
            name:"二次网回水温度",
            id:item.pp_id,
            qu:"二次网回水温度"
          })
        }
        if(item.pp_name == "二次供水压力1"){
          ids2.push(item.pp_id);
          this.ecwhs.push({
            name:"二次网供水压力",
            id:item.pp_id,
            qu:"二次网供水压力"
          })
        }
        if(item.pp_name == "二次回水压力1"){
          ids2.push(item.pp_id);
          this.ecwhs.push({
            name:"二次网回水压力",
            id:item.pp_id,
            qu:"二次网回水压力"
          })
        }
      });


      var data_1 = {
        Start:this.$getTime((new Date()).getTime()-1000*60*60*24*6).t1,
        End:this.$getTime((new Date())).t1,
        /* Start:'2021-11-28 17:08:46',
         End:'2021-12-03 17:08:46',*/
        Duration:'1',
        DurationStr:'日',
        param_id:ids
      };
      var data2 = {
        Start:this.$getTime((new Date()).getTime()-1000*60*60*24*6).t1,
        End:this.$getTime((new Date())).t1,
        /* Start:'2021-11-28 17:08:46',
         End:'2021-12-03 17:08:46',*/
        Duration:'1',
        DurationStr:'日',
        param_id:ids2
      };
      this.getCuverData2(data_1,'供水温度');
      this.getCuverData2(data2,'回水温度');
      
    })
    this.getDPDevice();
    this.getEngerData();
    this.getEngerData2();
    this.getEngerData3();
    this.getRealTimeData();
    this.getAlarmList();
    this.getBindDevice()


  },
  methods:{
    toHome(){
      var url = this.$router.resolve({
        name:"home",
        query:{
          key:this.$route.query.key
        }
      })
      window.open(url.href,"_blank")
    },
    //能耗曲线
    drawEchart1(xAxis,data1,data2){
      echart1 = echarts.init(document.querySelector("#echart1"));
      echart1.clear();
      var option = {
        tooltip:{
          trigger:"axis"
        },
        color:["rgba(38,183,231,1)","rgba(228,218,8,1)"],
        legend: {
          data: ['实际能耗', '指标能耗'],
          /*textStyle:{
              color:'#ffffff'
          },*/
          itemWidth:6,
          itemHeight:6,
          itemGap:10,
          icon:'rect',
          top: 10,
          right:20,

          textStyle:{
            color:'#ffffff',
            rich:{
              x:{
                color:'rgba(38,183,231,1)',
                fontSize:this.$fontSize(14),
              },
              m:{
                color:"rgba(228,218,8,1)",
                fontSize:this.$fontSize(14),
              }
            },
          },
          formatter:function(a){
            var val = '';
            switch(a){
              case '实际能耗':
                val = `{x|${a}}`;
                break;
              case '指标能耗':
                val = `{m|${a}}`;
                break;
            }
            return val
          }
        },
        grid:{
          bottom:20,
          top:40,
          left:20,
          containLabel:true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data:xAxis,
          axisLabel:{
            color:"#ffffff"
          },
          axisLine:{
            color:"#5b94b2"
          },
          axisTick:{
            show:false
          }
        },
        yAxis: {
          type: 'value',
          name:"℃",
          nameTextStyle:{
            color:"#ffffff"
          },
          axisLabel:{
            color:"#ffffff"
          },
          axisLine:{
            show:false
          },
          splitLine:{
            lineStyle:{
              color:"#182c50"
            }
          }
        },
        series: [
          {
            name:"实际能耗",
            data:data1,
            type: 'line',
            symbol:"none",
            smooth:true,
            lineStyle:{
              width:1,
              color:"rgba(38,183,231,1)"
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {offset: 0, color: 'rgba(38,183,231,0.8)'}, // 0% 处的颜色
                  {offset: 1, color: 'rgba(38,183,231,0)'} // 100% 处的颜色
                ],
                global: false // 缺省为 false
              }
            }
          },
          {
            name:"指标能耗",
            data: data2,
            type: 'line',
            symbol:"none",
            smooth:true,
            lineStyle:{
              width:1,
              color:"rgba(228,218,8,1)"
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {offset: 0, color: 'rgba(228,218,8,0.8)'}, // 0% 处的颜色
                  {offset: 1, color: 'rgba(228,218,8,0)'} // 100% 处的颜色
                ],
                global: false // 缺省为 false
              }
            }
          }
        ]
      };
      echart1.setOption(option);
    },
    //温度曲线
    drawEchart2(xAxis,data1,data2){
      echart2 = echarts.init(document.querySelector("#echart2"));
      echart2.clear();
      var option = {
        tooltip:{
          trigger:"axis"
        },
        color:["#4967f8","#ff0f70"],
        legend: {
          data: ['温度', '修正温度'],
          /*textStyle:{
              color:'#ffffff'
          },*/
          itemWidth:6,
          itemHeight:6,
          itemGap:10,
          icon:'rect',
          top: 10,
          right:20,

          textStyle:{
            color:'#ffffff',
            rich:{
              x:{
                color:'#4967f8',
                fontSize:this.$fontSize(14),
              },
              m:{
                color:"#ff0f70",
                fontSize:this.$fontSize(14),
              }
            },
          },
          formatter:function(a){
            var val = '';
            switch(a){
              case '温度':
                val = `{x|${a}}`;
                break;
              case '修正温度':
                val = `{m|${a}}`;
                break;
            }
            return val
          }
        },
        grid:{
          bottom:20,
          top:40,
          left:20,
          containLabel:true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxis,
          axisLabel:{
            color:"#ffffff"
          },
          axisLine:{
            color:"#5b94b2"
          },
          axisTick:{
            show:false
          }
        },
        yAxis: {
          type: 'value',
          name:"℃",
          nameTextStyle:{
            color:"#ffffff"
          },
          axisLabel:{
            color:"#ffffff"
          },
          axisLine:{
            show:false
          },
          splitLine:{
            lineStyle:{
              color:"#182c50"
            }
          }
        },
        series: [
          {
            name:"温度",
            data: data1,
            type: 'line',
            smooth:true,
            lineStyle:{
              width:1,
              color:"#4967f8"
            },
          },
          {
            name:"修正温度",
            data: data2,
            type: 'line',
            smooth:true,
            lineStyle:{
              width:1,
              color:"#ff0f70"
            },
          }
        ]
      };
      echart2.setOption(option);
    },
    drawEchart3(){
      echart3 = echarts.init(document.querySelector('#echart3'));
      echart3.clear();
      var datas = {
        value: this.gsyl_value,
        title: "一次网供水压力",
        type: 1,
        radiusType: 1
      };

      var fontColor = "#fff";
      let noramlSize = 10;
      let center = ["50%", "90%"];
      let nqColor = [
        [datas.value / 2.5, new echarts.graphic.LinearGradient(
            0, 0, 1, 0, [{
              offset: 0,
              color: '#1addc0',
            }, {
              offset: 0.5,
              color: '#1addc0',
            },
              {
                offset: 1,
                color: '#1addc0',
              }
            ]
        )],
        [1, "#08433a"]
      ];

      var option = {
        title: {
          show: false,
          x: "center",
          bottom: "6%",
          text: datas.title,
          textStyle: {
            fontWeight: "700",
            fontSize: 16,
            color: fontColor
          }
        },
        tooltip: {
          show: false
        },
        grid:{
          top:0,
          left:0,
          right:0,
          bottom:0
        },
        animation:false,
        series: [
          {
            name: "刻度文字",
            type: "gauge",
            radius: '64%',
            center: center,
            startAngle: 180,
            endAngle: 0,
            z: 7,
            splitNumber: 1,
            min: 0,
            max: 2.5,
            axisTick: {
              show: false,
              lineStyle: {
                color: "#0af",
                width: 1
              },
              length: 8,
              splitNumber: 2
            },
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                width: 24,
                opacity: 0
              }
            },
            axisLabel: {
              distance: -68,
              fontSize: noramlSize,
              color: fontColor,
            },

            pointer: {
              show: true,
              width: 3,
              length: "180%"
            },
            itemStyle: {
              normal: {
                //color: "#0af",//wqColor
                color:new echarts.graphic.LinearGradient(
                    0, 0, 1, 0, [{
                      offset: 0,
                      color: '#159b74',
                    }, {
                      offset: 0.5,
                      color: '#159b74',
                    },
                      {
                        offset: 1,
                        color: '#159b74',
                      }
                    ]
                )
              }
            },
            detail: {
              show: false,
              offsetCenter: [0, "30%"],//显示数值的位置
              rich: {
                value: {
                  fontSize: 25,
                  lineHeight: 10,
                  color: "#1e87f0",
                  fontWeight: "700"
                },
                company: {
                  fontSize: 16,
                  lineHeight: 20,
                  color: "#1e87f0"
                }
              }
            },
            data: [datas.value]
          },
          {
            name: "内层盘",
            type: "gauge",
            z: 6,
            radius: '160%',
            startAngle: 180,
            endAngle: 0,
            center: center,
            axisLine: {
              lineStyle: {
                color: nqColor,
                width: 24,
                opacity: 0.9
              }
            },
            splitNumber: -1,
            min: 0,
            max: 2.5,
            axisTick: {
              show: false
            },

            axisLabel: {
              show: false
            },
            pointer: {
              show: false
            },

            detail: {
              show: 0
            }
          }
        ]
      };
      echart3.setOption(option);
    },
    drawEchart4(){
      echart4 = echarts.init(document.querySelector('#echart4'));
      echart4.clear();
      var datas = {
        value:  this.hsyl_value,
        title: "一次网回水压力",
        type: 1,
        radiusType: 1
      };

      var fontColor = "#fff";
      let noramlSize = 10;
      let center = ["50%", "90%"];
      let nqColor = [
        [datas.value / 2.5, new echarts.graphic.LinearGradient(
            0, 0, 1, 0, [{
              offset: 0,
              color: '#4967f8',
            }, {
              offset: 0.5,
              color: '#4967f8',
            },
              {
                offset: 1,
                color: '#4967f8',
              }
            ]
        )],
        [1, "#161f4b"]
      ];

      var option = {
        title: {
          show: false,
          x: "center",
          bottom: "6%",
          text: datas.title,
          textStyle: {
            fontWeight: "700",
            fontSize: 16,
            color: fontColor
          }
        },
        tooltip: {
          show: false
        },
        grid:{
          top:0,
          left:0,
          right:0,
          bottom:0
        },
        animation:false,
        series: [
          {
            name: "刻度文字",
            type: "gauge",
            radius: '64%',
            center: center,
            startAngle: 180,
            endAngle: 0,
            z: 7,
            splitNumber: 1,
            min: 0,
            max: 2.5,
            axisTick: {
              show: false,
              lineStyle: {
                color: "#0af",
                width: 1
              },
              length: 8,
              splitNumber: 2
            },
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                width: 24,
                opacity: 0
              }
            },
            axisLabel: {
              distance: -68,
              fontSize: noramlSize,
              color: fontColor,
            },

            pointer: {
              show: true,
              width: 3,
              length: "180%"
            },
            itemStyle: {
              normal: {
                //color: "#0af",//wqColor
                color:new echarts.graphic.LinearGradient(
                    0, 0, 1, 0, [{
                      offset: 0,
                      color: '#2344e4',
                    }, {
                      offset: 0.5,
                      color: '#2344e4',
                    },
                      {
                        offset: 1,
                        color: '#2344e4',
                      }
                    ]
                )
              }
            },
            detail: {
              show: false,
              offsetCenter: [0, "30%"],//显示数值的位置
              rich: {
                value: {
                  fontSize: 25,
                  lineHeight: 10,
                  color: "#1e87f0",
                  fontWeight: "700"
                },
                company: {
                  fontSize: 16,
                  lineHeight: 20,
                  color: "#1e87f0"
                }
              }
            },
            data: [datas.value]
          },
          {
            name: "内层盘",
            type: "gauge",
            z: 6,
            radius: '160%',
            startAngle: 180,
            endAngle: 0,
            center: center,
            axisLine: {
              lineStyle: {
                color: nqColor,
                width: 24,
                opacity: 0.9
              }
            },
            splitNumber: -1,
            min: 0,
            max: 2.5,
            axisTick: {
              show: false
            },

            axisLabel: {
              show: false
            },
            pointer: {
              show: false
            },

            detail: {
              show: 0
            }
          }
        ]
      };
      echart4.setOption(option);
    },
    drawEchart5(){
      echart5 = echarts.init(document.querySelector('#echart5'));
      echart5.clear();
      var TP_value = this.wdgw_value;  // 温度
      var kd = [];        // 刻度
      var showValue = ''; // 显示内容 温度数字
      var boxPosition = [-20, -10];  // 显示内容的定位

// 刻度使用柱状图模拟，短设置1，长的设置3；构造一个数据
      for (var i = 0, len = 115; i <= len; i++) {
        if (i < 10 || i > 110) {
          kd.push('')
        }
        else {
          if ((i - 10) % 20 === 0) {
            kd.push('32');
          }
          else {
            kd.push('');
          }
        }
      }
      if (TP_value > 100) {
        showValue = 100
      }
      else {
        if (TP_value < -60) {
          showValue = -60
        }
        else {
          showValue = TP_value
        }
      }
// 避免温度过低 右侧指示内容出界
      if (TP_value < -10) {
        boxPosition = [65, -120];
      }

// 因为柱状初始化为0，温度存在负值，所以加上负值60和空出距离10


      var option = {
        title: {
          text: '温度计',
          show: false,
          textStyle: {
            color: '#fff'
          }
        },
        yAxis: [
          {
            show: false,
            data: [],
            min: 0,
            max: 115,
            axisLine: {
              show: false
            }
          },
          {
            show: false,
            min: 0,
            max: 50,
          },
          {
            type: 'category',
            data: ['', '', '', '', '', '', '', '', '', '', ''],
            position: 'right',
            offset: 110,
            axisLabel: {
              fontSize: 18,
              color: 'white',
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
          }
        ],
        xAxis: [
          {
            show: false,
            min: -10,
            max: 80,
            data: []
          },
          {
            show: false,
            min: -10,
            max: 80,
            data: []
          },
          {
            show: false,
            min: -10,
            max: 80,
            data: []
          },
          {
            show: false,
            min: -5,
            max: 80,
          }
        ],
        grid:{
          top:4,
          bottom:10,
          left:40
        },
        animation:false,
        series: [
          {
            name: '条/温度计柱',
            type: 'bar',
            // 对应上面XAxis的第一个对象配置
            xAxisIndex: 0,
            data: [{
              value: (showValue + 10),
              label: {
                normal: {
                  show: true,
                  position: boxPosition,
                  backgroundColor: {
                    image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Rjg3RDQ5OEI2MkZEMTFFQzhEMUJGNjE1OTc3RkI5NzkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Rjg3RDQ5OEM2MkZEMTFFQzhEMUJGNjE1OTc3RkI5NzkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpGODdENDk4OTYyRkQxMUVDOEQxQkY2MTU5NzdGQjk3OSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpGODdENDk4QTYyRkQxMUVDOEQxQkY2MTU5NzdGQjk3OSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PqzssUAAAABpSURBVHjaYhSdXcJACmBiIBHANJwG4jQg5iFWgwkQzwTi51DamFgn8UBtOgPFGLbi84MxNluJ9TQjFDOw4FF0DohnAfEyIP4ME0TX8AWIl0MVnsFmCgs+0/BpMCY24hj///9PUkwDBBgAeNYWMBvEoGMAAAAASUVORK5CYII=', //文字框背景图
                  },
                  width: 20,
                  height: 20,
                  padding: [0, -4, 0, 0],
                  formatter: '{back|  }',
                  rich: {
                    back: {
                      align: 'right',
                      lineHeight: 36,
                      fontSize: 24,
                      fontFamily: '微软雅黑',
                    },
                    unit: {
                      align: 'right',
                      fontFamily: '微软雅黑',
                      fontSize: 14,
                      lineHeight: 36,
                      color: '#fff'
                    },
                    downTxt: {
                      lineHeight: 36,
                      fontSize: 24,
                      align: 'right',
                    }
                  }
                }
              }
            }],
            barWidth: 8,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                  offset: 0,
                  color: '#1addc0'
                }, {
                  offset: 0.5,
                  color: '#1addc0'
                }, {
                  offset: 1,
                  color: '#1addc0'
                }])
              }
            },
            z: 2
          },
          {
            name: '白框/里面填充柱',
            type: 'bar',
            xAxisIndex: 1,
            barGap: '-100%',
            data: [114],
            barWidth: 8,
            itemStyle: {
              normal: {
                color: '#08433a',
                barBorderRadius: 50,
              }
            },
            z: 1
          },
          {
            name: '外框',
            type: 'bar',
            xAxisIndex: 2,
            barGap: '-100%',
            data: [115],
            barWidth: 12,
            itemStyle: {
              normal: {
                color: '#1addc0',
                barBorderRadius: 50,
              }
            },
            z: 0
          },
          {
            name: '圆',
            type: 'scatter',
            hoverAnimation: false,
            data: [0],
            xAxisIndex: 0,
            symbolSize: 8,
            itemStyle: {
              normal: {
                color: '#1addc0',
                opacity: 1,
              }
            },
            z: 2
          },
          {
            name: '白圆/温度计圆下方圆',
            type: 'scatter',
            hoverAnimation: false,
            data: [0],
            xAxisIndex: 1,
            symbolSize: 14,
            itemStyle: {
              normal: {
                color: '#08433a',
                opacity: 1,
              }
            },
            z: 1
          },
          {
            name: '外圆/最外层圆',
            type: 'scatter',
            hoverAnimation: false,
            data: [0],
            xAxisIndex: 2,
            symbolSize: 20,
            itemStyle: {
              normal: {
                color: '#1addc0',
                opacity: 1,
              }
            },
            z: 0
          },
          {
            name: '刻度',
            type: 'bar',
            yAxisIndex: 0,
            xAxisIndex: 3,
            label: {
              normal: {
                show: true,
                position: 'right',
                distance: 0,
                color: 'white',
                fontSize: 10,
                formatter: function(params) {
                  if (params.dataIndex > 130 || params.dataIndex < 10) {
                    return '';
                  } else {
                    if ((params.dataIndex - 10) % 20 === 0) {
                      return params.dataIndex - 10;
                    } else {
                      return '';
                    }
                  }
                }
              }
            },
            barGap: '40%',
            data: kd,
            barWidth: 1,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                  offset: 0,
                  color: '#1addc0'
                }, {
                  offset: 0.5,
                  color: '#1addc0'
                }, {
                  offset: 0.51,
                  color: '#1addc0'
                }, {
                  offset: 1,
                  color: '#1addc0'
                }]),
              }
            },
            z: 0
          }]
      };
      echart5.setOption(option);
    },
    drawEchart6(){
      echart6 = echarts.init(document.querySelector('#echart6'));
      echart6.clear();
      var TP_value = this.ylhw_value;  // 温度
      var kd = [];        // 刻度
      var showValue = ''; // 显示内容 温度数字
      var boxPosition = [-20, -10];  // 显示内容的定位

// 刻度使用柱状图模拟，短设置1，长的设置3；构造一个数据
      for (var i = 0, len = 115; i <= len; i++) {
        if (i < 10 || i > 110) {
          kd.push('')
        }
        else {
          if ((i - 10) % 20 === 0) {
            kd.push('32');
          }
          else {
            kd.push('');
          }
        }
      }
      if (TP_value > 100) {
        showValue = 100
      }
      else {
        if (TP_value < -60) {
          showValue = -60
        }
        else {
          showValue = TP_value
        }
      }
// 避免温度过低 右侧指示内容出界
      if (TP_value < -10) {
        boxPosition = [65, -120];
      }

// 因为柱状初始化为0，温度存在负值，所以加上负值60和空出距离10


      var option = {
        title: {
          text: '温度计',
          show: false,
          textStyle: {
            color: '#fff'
          }
        },
        yAxis: [
          {
            show: false,
            data: [],
            min: 0,
            max: 115,
            axisLine: {
              show: false
            }
          },
          {
            show: false,
            min: 0,
            max: 50,
          },
          {
            type: 'category',
            data: ['', '', '', '', '', '', '', '', '', '', ''],
            position: 'right',
            offset: 110,
            axisLabel: {
              fontSize: 18,
              color: 'white',
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
          }
        ],
        xAxis: [
          {
            show: false,
            min: -10,
            max: 80,
            data: []
          },
          {
            show: false,
            min: -10,
            max: 80,
            data: []
          },
          {
            show: false,
            min: -10,
            max: 80,
            data: []
          },
          {
            show: false,
            min: -5,
            max: 80,
          }
        ],
        grid:{
          top:4,
          bottom:10,
          left:40
        },
        animation:false,
        series: [
          {
            name: '条/温度计柱',
            type: 'bar',
            // 对应上面XAxis的第一个对象配置
            xAxisIndex: 0,
            data: [{
              value: (showValue + 10),
              label: {
                normal: {
                  show: true,
                  position: boxPosition,
                  backgroundColor: {
                    image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTJENTUzMTQ2MkZDMTFFQzhEMzBBMTY4Q0U4NDA4NUMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTJENTUzMTU2MkZDMTFFQzhEMzBBMTY4Q0U4NDA4NUMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpFMkQ1NTMxMjYyRkMxMUVDOEQzMEExNjhDRTg0MDg1QyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFMkQ1NTMxMzYyRkMxMUVDOEQzMEExNjhDRTg0MDg1QyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgzVx1EAAABpSURBVHjaYlR2ecJACmBiIBHANJwG4jQg5iFWgwkQzwTi51DamFgn8UBtOgPFGLbi84MxNluJ9TQjFDOw4FF0DohnAfEyIP4ME0TX8AWIl0MVnsFmCgs+0/BpMCY24hj///9PUkwDBBgA0boWV+XsWm0AAAAASUVORK5CYII=', //文字框背景图
                  },
                  width: 20,
                  height: 20,
                  padding: [0, -4, 0, 0],
                  formatter: '{back|  }',
                  rich: {
                    back: {
                      align: 'right',
                      lineHeight: 46,
                      fontSize: 24,
                      fontFamily: '微软雅黑',
                    },
                    unit: {
                      align: 'right',
                      fontFamily: '微软雅黑',
                      fontSize: 14,
                      lineHeight: 36,
                      color: '#fff'
                    },
                    downTxt: {
                      lineHeight: 36,
                      fontSize: 24,
                      align: 'right',
                    }
                  }
                }
              }
            }],
            barWidth: 8,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                  offset: 0,
                  color: '#4967f8'
                }, {
                  offset: 0.5,
                  color: '#4967f8'
                }, {
                  offset: 1,
                  color: '#4967f8'
                }])
              }
            },
            z: 2
          },
          {
            name: '白框/里面填充柱',
            type: 'bar',
            xAxisIndex: 1,
            barGap: '-100%',
            data: [114],
            barWidth: 8,
            itemStyle: {
              normal: {
                color: '#161f4b',
                barBorderRadius: 50,
              }
            },
            z: 1
          },
          {
            name: '外框',
            type: 'bar',
            xAxisIndex: 2,
            barGap: '-100%',
            data: [115],
            barWidth: 12,
            itemStyle: {
              normal: {
                color: '#4967f8',
                barBorderRadius: 50,
              }
            },
            z: 0
          },
          {
            name: '圆',
            type: 'scatter',
            hoverAnimation: false,
            data: [0],
            xAxisIndex: 0,
            symbolSize: 8,
            itemStyle: {
              normal: {
                color: '#4967f8',
                opacity: 1,
              }
            },
            z: 2
          },
          {
            name: '白圆/温度计圆下方圆',
            type: 'scatter',
            hoverAnimation: false,
            data: [0],
            xAxisIndex: 1,
            symbolSize: 14,
            itemStyle: {
              normal: {
                color: '#0C2E6D',
                opacity: 1,
              }
            },
            z: 1
          },
          {
            name: '外圆/最外层圆',
            type: 'scatter',
            hoverAnimation: false,
            data: [0],
            xAxisIndex: 2,
            symbolSize: 20,
            itemStyle: {
              normal: {
                color: '#4967f8',
                opacity: 1,
              }
            },
            z: 0
          },
          {
            name: '刻度',
            type: 'bar',
            yAxisIndex: 0,
            xAxisIndex: 3,
            label: {
              normal: {
                show: true,
                position: 'right',
                distance: 0,
                color: 'white',
                fontSize: 10,
                formatter: function(params) {
                  if (params.dataIndex > 130 || params.dataIndex < 10) {
                    return '';
                  } else {
                    if ((params.dataIndex - 10) % 20 === 0) {
                      return params.dataIndex - 10;
                    } else {
                      return '';
                    }
                  }
                }
              }
            },
            barGap: '40%',
            data: kd,
            barWidth: 1,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                  offset: 0,
                  color: '#4967f8'
                }, {
                  offset: 0.5,
                  color: '#4967f8'
                }, {
                  offset: 0.51,
                  color: '#4967f8'
                }, {
                  offset: 1,
                  color: '#4967f8'
                }]),
              }
            },
            z: 0
          }]
      };
      echart6.setOption(option);
    },
    drawEchart7(xAxis,legend,seriesData){
      echart7 = echarts.init(document.querySelector("#echart7"));
      echart7.clear();
      var option = {
        tooltip:{
          trigger:"axis"
        },
        color:["rgba(38,183,231,1)","rgba(228,218,8,1)"],
        legend:{
          data:legend,
          top: 0,
          right:0,
          textStyle:{
            color:"#ffffff",
            fontSize:this.$fontSize(12)
          }
        },
        grid:{
          bottom:10,
          top:40,
          left:20,
          containLabel:true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxis,
          axisLabel:{
            color:"#ffffff"
          },
          axisLine:{
            show:false,
            color:"#5b94b2"
          },
          axisTick:{
            show:false
          }
        },
        yAxis: {
          type: 'value',
          name:"℃",
          nameTextStyle:{
            color:"#ffffff"
          },
          axisLabel:{
            color:"#ffffff"
          },
          axisLine:{
            show:false
          },
          splitLine:{
            show:false,
            lineStyle:{
              color:"#182c50"
            }
          }
        },
        series: seriesData
      };
      echart7.setOption(option);
    },
    drawEchart8(xAxis,legend,seriesData){
      echart8 = echarts.init(document.querySelector("#echart8"));
      echart8.clear();
      var option = {
        tooltip:{
          trigger:"axis"
        },
        color:["rgba(46,175,36,1)","rgba(228,218,8,1)"],
        legend:{
          data:legend,
          top: 0,
          right:0,
          textStyle:{
            color:"#ffffff",
            fontSize:this.$fontSize(12)
          }
        },
        grid:{
          bottom:10,
          top:40,
          left:20,
          containLabel:true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxis,
          axisLabel:{
            color:"#ffffff"
          },
          axisLine:{
            show:false,
            color:"#5b94b2"
          },
          axisTick:{
            show:false
          }
        },
        yAxis: {
          type: 'value',
          name:"℃",
          nameTextStyle:{
            color:"#ffffff"
          },
          axisLabel:{
            color:"#ffffff"
          },
          axisLine:{
            show:false
          },
          splitLine:{
            show:false,
            lineStyle:{
              color:"#182c50"
            }
          }
        },
        series: seriesData
      };
      echart8.setOption(option);
    },
    to3D(){
      var ip = this.$axios_ip.defaults.baseURL;
   /*   if(this.extInfo.device_attribute_data.beng_type == "monitor_template"){
        var zeiot_token = this.$cookie.get("zeiot_token");
       var url = ip+'/template/system/canvas-template-plus/preview.html?monitor_id='+this.extInfo.device_attribute_data.monitor_3d+'&token='+zeiot_token+'&system_id='+this.$cookie.get("user_system_id")+'&project_id=null&MenuId=1&user_id='+this.$cookie.get("user_id");
        window.location.href = url;
        return false
      }
      if(this.extInfo.device_attribute_data.beng_type=="monitor_other"){
        url = ip+'/template/project/monitoring-list/simulated_scene.html?monitor_id=' + this.extInfo.device_attribute_data.monitor_3d + '&deviceID='+this.device_id+'&MenuId=1' + '&salt=' + this.$cookie.get("user_salt");
        window.location.href = url;
        return false
      }*/

      var name = "threeMode1_2";
      if(this.extInfo.device_attribute_data.beng_type=='1_2'){
        name = "threeMode1_2";
      }
      if(this.extInfo.device_attribute_data.beng_type=='1_3'){
        name = "threeMode1_3";
      }
      if(this.extInfo.device_attribute_data.beng_type=='2_2'){
        name = "threeMode2_2";
      }
      if(this.extInfo.device_attribute_data.beng_type=='2_3'){
        name = "threeMode2_3";
      }
      if(this.extInfo.device_attribute_data.beng_type=='3_2'){
        name = "threeMode3_2";
      }
      if(this.extInfo.device_attribute_data.beng_type=='3_3'){
        name = "threeMode3_3";
      }
      if(this.extInfo.device_attribute_data.beng_type=='4_2'){
        name = "threeMode4_2";
      }
      if(this.extInfo.device_attribute_data.beng_type=='4_3'){
        name = "threeMode4_3";
      }
      if(this.extInfo.device_attribute_data.beng_type=='monitor_template'){
        name = "monitor_3d";
      }
      if(this.extInfo.device_attribute_data.beng_type=='monitor_other'){
        name = "monitor_3d";
      }
      this.$router.push({
        name:name,
        query:{
          key:this.$route.query.key,
          name:this.name,
          id:this.device_id,
          dp_id:this.$route.query.dp_id,
          m3_i:this.extInfo.device_attribute_data.monitor_3d,
          beng_type:this.extInfo.device_attribute_data.beng_type
        }
      })
    },
    /*
    * =========================   天气 ===========================
    * */
    //获取天气型号的设备
    getDPDevice(){
      var url = '/api/user/device-protocol/device-options?dp_name=天气测试';
      this.$getZeiotApi(url,"get","",(res)=>{
        if(res.status == 200){
          var ID = res.data[0]?res.data[0].device_id:'';
          if(ID){
            this.getRealTimeData2(ID,()=>{
              var data = {
                Start:this.$getTime((new Date()).getTime()-1000*60*60*24*6).t1,
                End:this.$getTime((new Date())).t1,
                /* Start:'2021-11-28 17:08:46',
                 End:'2021-12-03 17:08:46',*/
                Duration:'1',
                DurationStr:'日',
                param_id:[this.wdCard.swwd1_id,this.wdCard.swwd1_xz_id]
              };
              this.getCuverData3(data);
            })
          }
        }
      })
    },
    //获取天气实时数据
    getRealTimeData2(ID,fun){
      var url = '/api/realtime/device/'+ID;
      this.$getZeiotApi(url,"get","",(res)=>{
        if(res.status == 200){
          if(!res.data.data){
            return
          }
          res.data.data.forEach((item)=>{
            if(item.name==this.wdCard.swwd1_name){//温度
              this.wdCard.swwd1 = item.value;
              this.wdCard.swwd1_id = item.id;
            }
            if(item.name==this.wdCard.swwd1_xz_name){//实时修正温度
              this.wdCard.swwd1_xz = item.value;
              this.wdCard.swwd1_xz_id = item.id;
            }
            if(item.name==this.wdCard.swwd2_name){//24小时温度
              this.wdCard.swwd2 = item.value
            }
            if(item.name==this.wdCard.swwd2_xz_name){//24小时修正温度
              this.wdCard.swwd2_xz = item.value
            }
            if(item.name==this.wdCard.swwd3_name){//48小时温度
              this.wdCard.swwd3 = item.value
            }
            if(item.name==this.wdCard.swwd3_xz_name){//48修正温度
              this.wdCard.swwd3_xz = item.value
            }
          });
          if(fun){
            fun()
          }
        }
      })
    },
    //获取温度曲线
    getCuverData3:function(data){
      var Start = data.Start;
      var End = data.End;
      var Duration = data.Duration;
      var DurationStr = data.DurationStr;
      var param_id = data.param_id.join(',');
      var url = '/api/history?ParamId='+param_id+'&Start='+Start+'&End='+End+'&Duration='+Duration+'&DurationStr='+DurationStr+'&Sort=ASC';
      this.$getZeiotApi(url,"get","",(res)=>{
        if(res.status == 200){
          var xAxis = [];
          var data1 = [];
          var data2 = [];
          var data = res.data.data.data;
          for(var key in data){
            if(data[key].length>0&&xAxis.length<data[key].length){
              xAxis=[];
            }
            if(key == this.wdCard.swwd1_id){
              data[key].forEach((item)=>{
                var time = (item.time.split(' ')[0]).split('/');
                data1.push([time[1]+'/'+time[2],item.value]);
                xAxis.push(time[1]+'/'+time[2]);
              })
            }
            if(key == this.wdCard.swwd1_xz_id){
              data[key].forEach((item)=>{
                var time = (item.time.split(' ')[0]).split('/');
                data2.push([time[1]+'/'+time[2],item.value]);
                xAxis.push(time[1]+'/'+time[2]);
              })
            }
          }
          this.drawEchart2(xAxis,data1,data2);
        }
      })
    },
    /*
    * ====================   设备实时数据 ====================
    * */
    //获取设备参数实时数据
    getRealTimeData(){
      var url = '/api/realtime/device/'+this.device_id;
      this.$getZeiotApi(url,"get","",(res)=>{
        if(res.status == 200){
          if(!res.data.data){
            return
          }
          res.data.data.forEach((item)=>{
            if(item.name==this.gsyl_name){//一次供水压力1
              this.gsyl_value = item.value
            }
            if(item.name==this.hsyl_name){//一次回水压力1
              this.hsyl_value = item.value
            }
            if(item.name==this.wdgw_name){//温度/供温1_1
              this.wdgw_value = item.value
            }
            if(item.name==this.ylhw_name){//压力/回温1_1
              this.ylhw_value = item.value
            }
            if(item.name==this.zydl_name){//总用电量1
              this.zydl_value = item.value
            }
            if(item.name==this.ljll_name){//补水累计流量1
              this.ljll_value = item.value
            }
           /* if(item.name==this.ywbj_name){//烟雾报警
              this.ywbj_value = item.value
            }
            if(item.name==this.jsbj_name){//积水报警
              this.jsbj_value = item.value
            }*/
          });
          this.drawEchart3();
          this.drawEchart4();
          this.drawEchart5();
          this.drawEchart6();
        }
      })
    },
    //获取参数列表
    getParamsList(device_id,fun){
      var url = '/api/user/device/'+device_id+'/parameter-options';
      this.$getZeiotApi(url,"get","",(res)=>{
        if(res.status == 200){
          this.paramsList = res.data;
          if(fun){
            fun(res)
          }
        }
      })
    },
    /*
    * ===================   能耗 ========================
    * */
    //获取能耗3天
    getEngerData(){
      var start = this.$getTime((new Date()).getTime()-1000*60*60*24*2).t1;
      var end = this.$getTime((new Date())).t1;
      var duration = '1';
      var durationStr = '日';
      var url = '/api/history/param?id='+this.device_id+'&name='+this.zljrl_name+'&method=区间值'+'&start='+start+'&end='+end+'&duration='+duration+'&durationStr='+durationStr+'&sort=ASC';
      this.$getZeiotApi(url,"get","",(res)=>{
        if(res.status == 200){
          var xAxis = [];
          var data1 = [];
          var data2 = [];
          var extra_val = this.extInfo.device_attribute_data.zbnh?this.extInfo.device_attribute_data.zbnh:0;//指标能耗 扩展属性
          var gnmj = this.extInfo.device_attribute_data.gnmj?this.extInfo.device_attribute_data.gnmj:0;//供暖面积  扩展属性
          res.data.forEach((item)=>{
            var time = (item.time.split(" ")[0]).split('/');
            xAxis.push(time[1]+'/'+time[2]);
            data1.push([time[1]+'/'+time[2],(item.value/24/3.6/gnmj*1000000).toFixed(2)]);
            data2.push([time[1]+'/'+time[2],extra_val]);
          })
          this.drawEchart1(xAxis,data1,data2);
        }
      })
    },
    //获取能耗24小时
    getEngerData2(){
      var start = this.$getTime((new Date()).getTime()-1000*60*60*24*1).d+' 00:00:00';
      var end = this.$getTime((new Date())).d+' 00:00:00';
      var duration = '1';
      var durationStr = '日';
      var url = '/api/history/param?id='+this.device_id+'&name='+this.zljrl_name+'&method=区间值'+'&start='+start+'&end='+end+'&duration='+duration+'&durationStr='+durationStr+'&sort=ASC';
      this.$getZeiotApi(url,"get","",(res)=>{
        if(res.status == 200){
          this.nh_24 = res.data[0]?res.data[0].value:0;
        }
      })
    },
    //获取能耗一个月
    getEngerData3(){
      var time = this.$getTime(new Date());
      var start = time.yyyy+'-'+time.mm+'-01 00:00:00';
      var end = time.t1;
      var day = time.dd;
      var duration = '1';
      var durationStr = '月';
      var url = '/api/history/param?id='+this.device_id+'&name='+this.zljrl_name+'&method=区间值'+'&start='+start+'&end='+end+'&duration='+duration+'&durationStr='+durationStr+'&sort=ASC';
      this.$getZeiotApi(url,"get","",(res)=>{
        if(res.status == 200){
          this.nh_avg_1 = res.data[0]?res.data[0].value:0;
          this.nh_avg_1 = parseInt(this.nh_avg_1/day);
        }
      })
    },
    /*
    * =================    二次网供水温度 供水压力 ==========
    * */
  //二次网供水
  getCuverData2(data,search){
    var Start = data.Start;
    var End = data.End;
    var Duration = data.Duration;
    var DurationStr = data.DurationStr;
    var param_id = data.param_id.join(',');
    var url = '/api/history?ParamId='+param_id+'&Start='+Start+'&End='+End+'&Duration='+Duration+'&DurationStr='+DurationStr+'&Sort=ASC';
    this.$getZeiotApi(url,"get","",(res)=>{
      if(res.status == 200){
        var data = res.data.data.data;
        var xAxis = [];
        var legend = [];
        var seriesData = [];
        var index = -1;
        for(var key in data){
          if(data[key].length>0){
            xAxis = [];
          }
          index = index+1;
          seriesData.push({
            name:'',
            data: [],
            type: 'line',
            symbol:'none',
            lineStyle:{
              width:1
            }
          });
          if(search == '供水温度'){
            this.ecwgs.forEach((item,index)=>{
              if(key == item.id){
                legend.push(item.qu);
                seriesData[index].name = item.qu;
                data[key].forEach((val)=>{
                  var time = (val.time.split(' ')[0]).split('/');
                  xAxis.push(time[1]+'/'+time[2]);
                  seriesData[index].data.push([time[1]+'/'+time[2],val.value])
                });
              }
            })
          }
          if(search == '回水温度'){
            this.ecwhs.forEach((item,index)=>{
              if(key == item.id){
                legend.push(item.qu);
                seriesData[index].name = item.qu;
                data[key].forEach((val)=>{
                  var time = (val.time.split(' ')[0]).split('/');
                  xAxis.push(time[1]+'/'+time[2]);
                  seriesData[index].data.push([time[1]+'/'+time[2],val.value])
                });
              }
            })
          }
        }
        if(search == '供水温度'){
          this.drawEchart7(xAxis,legend,seriesData);
        }
        if(search == '回水温度'){
          this.drawEchart8(xAxis,legend,seriesData);
        }
      }
    })
  },
    /*
    * =======================   报警 =================
    * */
    getAlarmList(){
      var url = "/api/user/alarm-logs/device/"+this.device_id+"?pageNo=1&pageSize=7";
      this.$getZeiotApi(url,"get","",(res)=>{
        if(res.status == 200){
          this.alarmList = res.data.data;
        }
      })
    },
    //获取设备扩展属性
    getExtData(dp_id){
      var url = "/heating/device-attribute/info?dp_id="+dp_id;
      this.$axios.get(url).then((res)=>{
        this.extInfo = {device_attribute_data:""};
       var has_ext = res.data.some((item)=>{
         if(item.device_attribute_id == this.device_id){
           this.extInfo = item;
           return true
         }
       })
        this.extInfo.device_attribute_data = this.extInfo.device_attribute_data?JSON.parse(this.extInfo.device_attribute_data):{
          gnmj:0,
          cnfs:'',
          xqmj:0,
          lldh:"",
          zbnh:0,
          beng_type:"1_2",
          monitor_2d:"",
          monitor_3d:"",
          video_num:1,
          videos:["","","",""]
        }
        var ip = this.$axios_ip.defaults.baseURL;
        if(this.extInfo.device_attribute_data.beng_type=="monitor_template"){
          var zeiot_token = this.$cookie.get("zeiot_token");
          this.monitor_2d_url = ip+'/template/system/canvas-template-plus/preview.html?monitor_id='+this.extInfo.device_attribute_data.monitor_2d+'&token='+zeiot_token+'&system_id='+this.$cookie.get("user_system_id")+'&project_id=null&MenuId=1&user_id='+this.$cookie.get("user_id");

        }
        if(this.extInfo.device_attribute_data.beng_type=="monitor_other"){
          this.monitor_2d_url = ip+'/template/project/monitoring-list/simulated_scene.html?monitor_id=' + this.extInfo.device_attribute_data.monitor_2d + '&MenuId=1' + '&salt=' + this.$cookie.get("user_salt");
        }
        this.extInfo.device_attribute_data.videos.forEach((item)=>{
          if(item){
            this.getVideoSrc(item,()=>{

            })
          }
        })
      }).catch((res)=>{
        console.log(res);
      })
    },
    getVideoSrc(src,fun){
      var s1 = src.split("/");
      var s2 = (s1[s1.length-1].split(".")[0]).split("_");
      var device_id = s2[0];
      var channel = s2[1];
      var url = "/api/gb/start/play?deviceid="+device_id+'&channel='+channel;
      this.$getZeiotApi(url,"get","",(res)=>{
        if(fun){
          fun()
        }
      })
    },
    /*
    * ======================= 单元阀 户阀数量 ==============================
    * */
    //获取已绑定换热站的小区
    getBindDevice(){
      this.unit_valve_num = 0;
      this.house_valve_num = 0;
      var url = "/heating/community/bind/device/info";
      this.$axios.post(url,{
        "zone_id": "",
        "community_id": ""
      }).then((res)=>{
        if(res.status == 200){
          res.data.forEach((item)=>{
            if(item.cbd_device_id == this.device_id){
              this.xiaoqu = item.community_id;
                this.getUnitValve(item.community_id);
                this.getHouseValve(item.community_id)
            }
          })

        }
      }).catch((res)=>{
        console.log(res)
      })
    },
    //获取小区下单元阀列表
    getUnitValve(id){
      var url = "/heating/device-unitvalve/info";
      this.$axios.post(url,{
        "zone_id": "",
        "community_id": id,
        "keyword": ""
      }).then((res)=>{
        if(res.status == 200){
          this.unit_valve_num = this.unit_valve_num+res.data.length;
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //获取小区下户阀列表
    getHouseValve(id){
      var url = "/heating/device-housesvalve/info";
      this.$axios.post(url,{
        "keyword": "",
        "zone_id": "",
        "community_id": id,
        "building_id": "",
        "unit_id": ""
      }).then((res)=>{
        if(res.status == 200){
          this.house_valve_num = this.house_valve_num+res.data.length;
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //去到单元阀页面
    toUnitPage(){
      this.$router.push({
        name:"unitValve",
        query:{
          device_id:this.device_id,
          dp_id:this.dp_id,
          key:this.$route.query.key
        }
      })
    },
    //去到户阀页
    toHousePage(){
      this.$router.push({
        name:"houseIndex",
        query:{
          device_id:this.device_id,
          dp_id:this.dp_id,
          key:this.$route.query.key,
          xiaoqu:this.xiaoqu
        }
      })
    }

  }
}
$(window).resize(function(){
  setTimeout(()=>{
    if(echart1){
      echart1.resize();
    }
    if(echart2){
      echart2.resize();
    }
    if(echart3){
      echart3.resize();
    }
    if(echart4){
      echart4.resize();
    }
    if(echart5){
      echart5.resize();
    }
    if(echart6){
      echart6.resize();
    }
    if(echart7){
      echart7.resize();
    }
    if(echart8){
      echart8.resize();
    }
  },1000)
})
</script>

<style scoped>

.beng_cont {
  width: 100%;
  height: 100%;
  padding: 0 0.1rem 0.1rem;
  box-sizing: border-box;
}
.main_page{
  background: #0a143f;
}

</style>