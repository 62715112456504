<template>
<div class="main_page">
  <main-header :title="title"></main-header>
  <div class="header_time" style="right: 3rem">
    <i class="iconfont icon-rili"></i>
    <span>{{time}}</span>
  </div>

  <homeBtn :show_back="true"></homeBtn>
  <div class="main_container">
    <div class="map_container" id="map_container"></div>

    <div class="left_btns">
      <div class="def_btn_1" @click="hideSide"><left-outlined /><right-outlined />{{ hide_side?"展开两侧":"收起两侧" }}</div>
      <homeMenu></homeMenu>
<!--      <div class="def_btn_1"><swap-outlined />3D模式</div>-->
    </div>
    <div :class='{"left_cards":true,"hideLeft":hide_side}'>
      <div class="left_card1" style="padding-top: 0.1rem">
       <div style="width: calc(100% - 0.2rem);height: auto;display: flex;flex-wrap: wrap;margin:0  0.1rem;box-sizing: border-box;max-height: calc(100% - 0.1rem);overflow: auto;">
         <div class="line_item" >
           <span class="check_customer" @click="showPoint" >
             <check-outlined class="dg" v-if="show_point"   />
           </span>
           <span title="监测点" style=" flex: 1;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">监测点</span>
         </div>
         <template v-for="(item,index) in classList" :key="index">
           <div class="line_item" >
           <span class="check_customer" :style='{"border-color":item.lineData.length?item.lineData[0].line_data.color:""}' @click="checkClass(index)">
             <check-outlined class="dg" :style='{"color":item.lineData.length?item.lineData[0].line_data.color:""}' v-if="check_class_ids.includes(item.line_group_id)" />
           </span>
             <span :title="item.line_group_name" style=" flex: 1;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{item.line_group_name}}</span>
           </div>
         </template>

       </div>
      </div>
      <div class="left_card2">
        <div class="left_card_tit">
          <span>统计图</span>
        </div>
        <div class="left_card_cont" style="border: 1px solid transparent">
          <table style="width: 60%;height: 1.7rem;margin: 0.1rem auto;">
            <tbody>
            <tr>
              <td style="color: #ffffff">管网的总长度</td>
              <td style="color: #48ece7" class="num_text">{{line_len?line_len.toFixed(2):line_len}}</td>
            </tr>
            <tr>
              <td style="color: #ffffff">监测管井数量</td>
              <td style="color: #47ff88" class="num_text">{{line_device_len>=10?line_device_len:"0"+line_device_len}}</td>
            </tr>
            <tr>
              <td style="color: #ffffff">设备在线数</td>
              <td style="color: #ffd452" class="num_text">{{device_online_num}}</td>
            </tr>
            </tbody>
          </table>
<!--          <div style="display: flex;flex-direction: column;align-items: center;justify-content:center;width: 100%;height: 100%;padding: 0.2rem;box-sizing: border-box">
            <div class="line_info_"><span>管网的总长度</span><span style="color: #48ece7">{{line_len?line_len.toFixed(2):line_len}}</span></div>
            <div class="line_info_"><span>监测管井数量</span><span style="color: #47ff88">{{line_device_len}}</span></div>
            <div class="line_info_"><span>设备在线数</span><span style="color: #ffd452">{{line_len?line_len.toFixed(2):line_len}}</span></div>
          </div>-->
        </div>
      </div>
      <div class="left_card3">
        <div class="left_card_tit">
          <span>报警信息</span>
        </div>
        <div class="left_card_cont">
          <div class="alarm_card" >
            <div class="alarm_table_head">
              <div>采集值</div>
              <div>发生时间</div>
            </div>
            <div class="alarm_table_body">
              <div class="alarm_table_tr" v-for="(item,index) in alarmList" :key="index">
                <div :title="item.al_content" :style='{"color":(item.al_is_stop?"#ffffff":"red")}' >{{ item.al_content }}</div>
                <div >{{ item.al_start_time }}</div>
              </div>
            </div>
          </div>
<!--          <div class="alarmTable">
            <table class="alarm_table">
              <thead>
              <tr>
                <td>时间</td>
                <td>采集值</td>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) in alarmList" :key="index">
                <td>{{ item.al_start_time }}</td>
                <td>{{ item.al_content }}</td>
              </tr>
              </tbody>
            </table>

          </div>-->
        </div>
      </div>
    </div>
    <div :class='{"right_cards":true,"hideRight":hide_side}' v-show="active_line">
      <div class="right_card1">
        <div class="right_card_tit">
          <span>{{ active_line_info.line_name }}</span>
        </div>
        <div class="right_card_cont">
          <div style="display: flex;align-items: flex-end;justify-content: center;" v-if="active_line_info.device_ids">
            <div class="c_1" style="width: 0.8rem;flex: unset">{{active_line_info.device_ids.length<10?0:parseInt(active_line_info.device_ids.length/10)}}</div>
            <div class="c_1" style="width: 0.8rem;flex: unset">{{ active_line_info.device_ids.length<10?active_line_info.device_ids.length:(active_line_info.device_ids.length%10) }}</div>
            <small style="color: #ffffff">个</small>
          </div>
          <div style="text-align: center;color:#ffffff;padding-top: 20px">监测点数量</div>

        </div>
        <div class="right_card_cont2">
          <div class="alarmTable" style="height: 100%">
            <div>
              <table class="alarm_table">
                <thead>
                <tr>
                  <td style="width:40%">监测点</td>
                  <td style="width:30%">压力（MPa）</td>
                  <td style="width:30%">温度（℃）</td>
                </tr>
                </thead>
              </table>
            </div>
            <div style="width: 100%;height: calc(100% - 0.28rem);overflow: auto;">
              <table class="alarm_table">
                <tbody>
                <tr v-for="(item,index) in dev_realData" :key="index">
                  <td style="width: 40%;word-break: break-all">{{ item.device_name }}</td>
                  <td style="width: 30%;word-break: break-all"><span v-if='item.pp_data'>{{item.pp_data["压力"]?item.pp_data["压力"]:"--"}}</span></td>
                  <td style="width: 30%;word-break: break-all"><span v-if='item.pp_data'>{{item.pp_data["温度"]?item.pp_data["温度"]:"--"}}</span></td>
                </tr>

                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
      <div class="right_card2">
        <div class="right_card_tit">
          <span>温度曲线</span>
        </div>
        <div class="right_card_cont">
          <div class="echarts" id="echart1" style="width: 100%;height: 100%"></div>
        </div>
      </div>
      <div class="right_card3">
        <div class="right_card_tit">
          <span>压力曲线</span>
        </div>
        <div class="right_card_cont">
          <div class="echarts" id="echart2" style="width: 100%;height: 100%"></div>
        </div>
      </div>
    </div>

  </div>
</div>
</template>

<script>
import * as echarts from "echarts"
import customMaterial from "@/until/customerMaterial";
var Cesium = require("cesium/Cesium");
var viewer = null;
var handler = null;
var resultLine = [];
var BillboardCollection = null;
var LabelCollection = null;
var BillboardCollectionObj = {};
var LabelCollectionObj = {};
var echart1 = null;
var echart2 = null;

export default {
  name: "pipeNetwork",
  data() {
    return {
      time: "",
      title: "智慧供热监控大屏",
      hide_side:false,
      lineData:[],
      line_len:0,//管线长度
      line_device_len:0,//监测点数量
      check_class_ids:[],
      active_line:"",
      active_line_info:{},
      dev_realData:{},
      dev_index:0,
      screen_data:{},
      line_dp_ids:[],
      cornerTypes: [Cesium.CornerType.ROUNDED, Cesium.CornerType.MITERED, Cesium.CornerType.BEVELED],
      alarmList:[],
      device_online_num:0,
      load_dev_index:0,
      classList:[],
      show_point:true,
      timer_dev:null,
      timer_real:null
    }
  },
  beforeUnmount() {
    clearInterval(this.timer_real);
    clearInterval(this.timer_dev);
  },
  mounted(){
    clearInterval(this.timer_real);
    clearInterval(this.timer_dev);
    this.time = this.$getTime(new Date()).t3;
    setInterval(()=>{
      this.time = this.$getTime(new Date()).t3;
    },1000)
    this.$axios.defaults.headers.common["Authorization"] = "bearer "+this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.getLineClass();
    this.initMap()
  },
  methods:{
    //初始化地图
    initMap() {
      viewer = new Cesium.Viewer("map_container", {
        contextOptions: {
          webgl: {
            loseContext: {
              // 允许丢弃3D渲染的缓存
              // 这可以在上下文丢失时帮助恢复
              loseContext: true,
            },
          },
        },
        animation: false,
        baseLayerPicker: false,//隐藏默认底图选择控件
        fullscreenButton: false,
        geocoder: false,
        homeButton: false,
        infoBox: false,
        sceneModePicker: false,
        selectionIndicator: false,
        timeline: false,
        navigationHelpButton: false,
        navigationInstructionsInitiallyVisible: false,
        shouldAnimate: true,
        imageryProvider: new Cesium.UrlTemplateImageryProvider({
          url: " http://webst01.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}",
          minimumLevel: 1,
          maximumLevel: 18
        }),
      });

      viewer.scene.fxaa = false;
      viewer.scene.postProcessStages.fxaa.enabled = false;
      viewer.camera.setView({
        destination: Cesium.Cartesian3.fromDegrees(118.158229, 39.633663, 1000)
      });
      this.$loadGaoDe(viewer);
      this.clickEvent()
      this.getLineData();
      this.$drawImageEntity(viewer);
      this.drawPoint();
      //this.hideGlobe();
    },
    clickEvent() {
      handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas)
      //点击
      handler.setInputAction((event) => {
        var cartesian = viewer.camera.pickEllipsoid(event.position, viewer.scene.globe.ellipsoid);
        if (cartesian) {
          //let cartographic = Cesium.Cartographic.fromCartesian(cartesian);
          // 弧度转为角度（经纬度）
         /* let lon = Cesium.Math.toDegrees(cartographic.longitude);  // 经度值
          let lat = Cesium.Math.toDegrees(cartographic.latitude);   // 纬度值*/
          var pick = viewer.scene.pick(event.position);
          if(pick&&pick.id&&pick.id instanceof Cesium.Entity){
            if(pick.id.id.includes("line_")){
              this.clickLine(pick);
            }
          }
          if(pick&&pick.id&&pick.primitive instanceof Cesium.Billboard){
            if(pick.id.includes("bill_")){
              this.clickPoint(pick)
            }
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },
    hideSide(){
      this.hide_side = !this.hide_side;
    },
    getLineData(){
      var url = "/heating/line/info";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.lineData = res.data;
          this.line_len = 0;
          this.line_device_len = 0;
          this.line_dp_ids = [];
          this.load_dev_index = 0;
          this.getDPList((dp_obj)=>{
            var device_ids = [];
            this.lineData.forEach((item)=>{
              device_ids = device_ids.concat(item.device_ids)
              this.line_device_len = this.line_device_len+item.device_ids.length;
              this.line_len = (item.line_len?(this.line_len+parseFloat(item.line_len)):this.line_len)
              item.line_data = item.line_data?JSON.parse(item.line_data):{
                "lineType":"管线",
                color: "rgba(235, 29, 78,1)",
                radius: 2,//半径
                shape: "1",//切面形状
                cornerType: "0",//拐角样式
                extrudedHeight: 4,//
                angle: 0,
                outline: false,//是否显示边框
                outlineWidth: 2,//边框宽度
                outlineColor: 'rgba(255,235,60,1)',//边框颜色
                areapos: [],
                width:4,//宽度
                dashLength:40,//虚线间隔
                intervalRatio:0.25,//虚线间隔
                dp_id:""
              }
              item.deviceList= [];
              if(item.line_data.dp_id){
                this.getDeviceList(item.line_data.dp_id,(deviceList)=>{
                  item.device_ids.forEach((val)=>{
                    deviceList.some((v)=>{
                      if(v.device_id == val){
                        if(dp_obj.hasOwnProperty(item.line_data.dp_id)){
                          v = Object.assign(v,dp_obj[item.line_data.dp_id])
                        }
                        item.deviceList.push(v);
                        return true
                      }
                    })
                  })
                  if(this.load_dev_index == this.lineData.length){
                    this.getAlarmList();
                  }
                  this.getDevicePoint(item.line_data.dp_id,item)
                })
              }else{
                this.load_dev_index = this.load_dev_index+1;
                if(this.load_dev_index == this.lineData.length){
                  this.getAlarmList();
                }
              }
              this.classList.some((val)=>{
                if(val.line_group_id == item.line_group_id){
                  val.lineData.push(item);
                  return true
                }
              })
            });

            this.getDeviceOnline(device_ids);
            this.drawAllResult()
          })
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    drawAllResult(fun) {
      var _this = this;
      var pos = [];
      this.lineData.forEach((item)=>{
        pos = pos.concat(item.line_data.areapos);
        if(resultLine[item.line_id]){
          viewer.entities.remove(resultLine[item.line_id]);
          resultLine[item.line_id] = null;
        }
      });
      resultLine = [];
      this.lineData.forEach((item, index) => {
        (function(obj){
          setTimeout(()=>{
            var resultArea = '';
            if (obj.line_data.areapos.length > 0) {
              resultArea = viewer.entities.add({
                show:true,
                id: "line_"+obj.line_id,
                description: obj.map_line_name,
                position: Cesium.Cartesian3.fromDegrees(obj.line_data.areapos[0][0], obj.line_data.areapos[0][1], obj.line_data.areapos[0][2]),
                label: {
                  text: obj.map_line_name,
                  font: '20px sans-serif',
                  pixelOffset: new Cesium.Cartesian2(-10, -10),
                  fillColor: Cesium.Color.fromCssColorString('#1890ff'),
                  scaleByDistance:new Cesium.NearFarScalar(10, 1, 100000, 0.2)
                },
                polylineVolume:obj.line_data.lineType=="管线"? {
                  positions: Cesium.Cartesian3.fromDegreesArrayHeights(obj.line_data.areapos.flat()),
                  cornerType: _this.cornerTypes[obj.line_data.cornerType],
                  material: Cesium.Color.fromCssColorString(obj.line_data.color)/*new Cesium.ImageMaterialProperty({
            image:require("../assets/images/test.png")
          })*/,//Cesium.Color.fromCssColorString(this.color),
                  shape: _this.computeCircle(obj.line_data.angle, obj.line_data.shape, obj.line_data.radius),
                  outline: obj.line_data.outline,
                  outlineWidth: obj.line_data.outlineWidth,
                  outlineColor: Cesium.Color.fromCssColorString(obj.line_data.outlineColor)
                }:{},
                polyline: obj.line_data.lineType!="管线"?{
                  positions: new Cesium.CallbackProperty(() => {
                    // PolygonHierarchy 定义多边形及其孔的线性环的层次结构（空间坐标数组）
                    return Cesium.Cartesian3.fromDegreesArrayHeights(obj.line_data.areapos.flat());
                  }, false),
                  width: obj.line_data.width,
                  material:_this.changeMaterial(obj.line_data)
                }:{}
              });
              resultLine[obj.line_id]=(resultArea);
            }
          })
        })(item,index)
      });
      if(fun){
        fun(pos)
      }
    },
    changeMaterial(line_data){
      var material = null;
      if(line_data.lineType=="实线"){
        material = this.solidLine(line_data);
      }
      if(line_data.lineType=="虚线"){
        material = this.dashedLine(line_data);
      }
      if(line_data.lineType=="双实线"){
        material = this.doubleLine(line_data);
      }
      if(line_data.lineType=="实虚线"){
        material = this.doubleDashLine(line_data);
      }
      if(line_data.lineType=="发光线"){
        material = this.glowLine(line_data);
      }
      return material
    },
    //实线
    solidLine(line_data){
      return new Cesium.PolylineDashMaterialProperty({
        color:Cesium.Color.fromCssColorString(line_data.color),
        gapColor:Cesium.Color.fromCssColorString(line_data.color),
        // dashLength:40,
        //dashPattern: 255
      })
    },
    //虚线
    dashedLine(line_data){
      return new Cesium.PolylineDashMaterialProperty({
        color:Cesium.Color.fromCssColorString(line_data.color),
        gapColor:Cesium.Color.TRANSPARENT,
        dashLength:line_data.dashLength,
        //dashPattern: 255
      })
    },
    //双实线
    doubleLine(line_data){
      return customMaterial.DoubleSolidLine({
        color:Cesium.Color.fromCssColorString(line_data.color),
        glowPower:0.25,
        taperPower:1,
      })
    },
    //实虚线
    doubleDashLine(line_data){
      return customMaterial.DashedSolidLine({
        color:Cesium.Color.fromCssColorString(line_data.color),
        dashLength:parseFloat(line_data.dashLength),//虚线间隔长度
        dashPattern:255,
        intervalRatio:line_data.intervalRatio,//两条线之间的空隙  占宽度的百分比
        v_polylineAngle:90,
        maskLength:10,
        sideWise:false,
      })
    },
    //发光线
    glowLine(line_data){
      return new Cesium.PolylineGlowMaterialProperty({
        color:Cesium.Color.fromCssColorString(line_data.color),
        glowPower:0.25,
        taperPower:1,
      })
    },
    computeCircle(angle, shape, radius) {
      var positions = [];
      for (var i = parseInt(angle); i < (360 + parseInt(angle)); i = i + parseInt(shape)) {
        var radian = Cesium.Math.toRadians(i);
        var x = radius * Math.cos(radian);
        var y = radius * Math.sin(radian);
        positions.push(new Cesium.Cartesian2(x, y));
      }
      return positions;
    },
    //温度曲线
    drawEchart1(xAxisData,data){
      if(!echart1){
        echart1 = echarts.init(document.querySelector("#echart1"));
      }
      echart1.clear();
      var option = {
        tooltip:{
          trigger:"axis"
        },
        grid:{
          bottom:30,
          top:40,
          left:20,
          right:10,
          containLabel:true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisData,
          axisLabel:{
            color:"#ffffff",
            fontSize:10,
            rotate:xAxisData.length>4?45:0
          },
          axisLine:{
            color:"#5b94b2"
          },
          axisTick:{
            show:false
          }
        },
        yAxis: {
          type: 'value',
          name:"℃",
          nameTextStyle:{
            color:"#ffffff"
          },
          axisLabel:{
            color:"#ffffff"
          },
          axisLine:{
            show:false
          },
          splitLine:{
            lineStyle:{
              color:"#182c50"
            }
          }
        },
        series: [
          {
            data: data,
            type: 'line',
            symbol:"none",
            smooth:true,
            lineStyle:{
              color:"rgba(38,183,231,1)"
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {offset: 0, color: 'rgba(38,183,231,0.8)'}, // 0% 处的颜色
                  {offset: 1, color: 'rgba(38,183,231,0)'} // 100% 处的颜色
                ],
                global: false // 缺省为 false
              }
            }
          }
        ]
      };
      echart1.setOption(option);

    },
    //压力曲线
    drawEchart2(xAxisData,data){
      if(!echart2){
        echart2 = echarts.init(document.querySelector("#echart2"));
      }
      echart2.clear();
      var option = {
        tooltip:{
          trigger:"axis"
        },
        grid:{
          bottom:30,
          top:40,
          left:20,
          right:10,
          containLabel:true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisData,
          axisLabel:{
            color:"#ffffff",
            fontSize:10,
            rotate:xAxisData.length>4?45:0
          },
          axisLine:{
            color:"#5b94b2"
          },
          axisTick:{
            show:false
          }
        },
        yAxis: {
          type: 'value',
          name:"MPa",
          nameTextStyle:{
            color:"#ffffff"
          },
          axisLabel:{
            color:"#ffffff"
          },
          axisLine:{
            show:false
          },
          splitLine:{
            lineStyle:{
              color:"#182c50"
            }
          }
        },
        series: [
          {
            data: data,
            type: 'line',
            symbol:"none",
            smooth:true,
            lineStyle:{
              color:"rgba(255,241,0,1)"
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {offset: 0, color: 'rgba(255,241,0,0.8)'}, // 0% 处的颜色
                  {offset: 1, color: 'rgba(255,241,0,0)'} // 100% 处的颜色
                ],
                global: false // 缺省为 false
              }
            }
          }
        ]
      };
      echart2.setOption(option);

    },
    //勾选管线分类
    checkClass(index){
      clearInterval(this.timer_dev);
      this.line_len = 0;
      this.line_device_len = 0;
      if(this.check_class_ids.includes(this.classList[index].line_group_id)){
        this.check_class_ids.splice(this.check_class_ids.indexOf(this.classList[index].line_group_id),1);
      }else{
        this.check_class_ids.push(this.classList[index].line_group_id)
      }
      this.classList.forEach((item,index)=>{
        if(this.check_class_ids.includes(item.line_group_id)){
          item.lineData.forEach((val)=>{
            if(resultLine[val.line_id]){
              resultLine[val.line_id].show = true
            }
          })
        }else{
          item.lineData.forEach((val)=>{
            if(resultLine[val.line_id]){
              resultLine[val.line_id].show = false;
            }
          })
        }
      })
      var device_ids = []
      this.lineData.forEach((item)=>{
        if(!item.line_group_id||this.check_class_ids.includes(item.line_group_id)){
          this.line_len = this.line_len+(item.line_len?parseFloat(item.line_len):0);
          this.line_device_len = this.line_device_len+item.device_ids.length;
          device_ids = device_ids.concat(item.device_ids);
        }
      })
      this.getDeviceOnline(device_ids)
      this.timer_dev = setInterval(()=>{
        this.getDeviceOnline(device_ids)
      },30000)

    },
    clickLine(pick){
      clearInterval(this.timer_real);
      this.active_line = "";
      var active_line = pick.id.id.split("line_")[1];
      this.dev_index = 0;
      this.dev_realData = {};
      this.lineData.some((item)=>{
        if(item.line_id == active_line){
          if(item.device_ids.length){
            this.active_line = active_line;
            this.active_line_info = item;
            item.deviceList.forEach((val)=>{
              if(!this.dev_realData.hasOwnProperty(val.device_id)){
                this.dev_realData[val.device_id] = val;
              }
            })
            this.getRealData();
            this.timer_real = setInterval(()=>{
              this.getRealData();
            },30000)
          }
          return true
        }
      })
    },
    //获取设备实时数据
    getRealData(){
      var url = "/heating/zeiot/device/read-time/all";
      var data = this.active_line_info.device_ids;
      this.$axios.post(url,data).then((res)=>{
        var xAxisData = [];
        var sericesData = [];
        var sericesData2 = [];
        var index = 0;
        for(var key in this.dev_realData){
          xAxisData.push(this.dev_realData[key].device_name);
          sericesData.push({name:this.dev_realData[key].device_name,value:""})
          sericesData2.push({name:this.dev_realData[key].device_name,value:""})
          if(res.data[key]){
            this.dev_realData[key].pp_data = {};
            res.data[key].forEach((item)=>{
              this.dev_realData[key].pp_data[item.name] = item.value;
              if(item.name == "温度"){
                sericesData[index].value = item.value;
              }
              if(item.name == "压力"){
                sericesData2[index].value = item.value;
              }
            })
          }
          index = index+1;
        }

        this.drawEchart1(xAxisData,sericesData)
        this.drawEchart2(xAxisData,sericesData2)
      }).catch((res)=>{

      })
    },
    //获取设备标点
    getDevicePoint(dp_id,obj){
      var url = "/heating/custom/mark/info/all?mark_type=0&mark_source_id="+dp_id;
      this.$axios.post(url).then((res)=>{
        if(res.status == 200){
          res.data.forEach((item,index)=>{
            if(obj.device_ids.includes(item.mark_id)){
              item.mark_lon = item.mark_lon?parseFloat(item.mark_lon):"";
              item.mark_lat = item.mark_lat?parseFloat(item.mark_lat):"";
              item.mark_height = item.mark_height?parseFloat(item.mark_height):0;
              obj.deviceList.some((val)=>{
                if(val.device_id == item.mark_id){
                  item = Object.assign(item,val)
                  return true
                }
              })
              if (item.mark_lon && item.mark_lat) {
                var obj2 = this.setBillLabel(item);
                BillboardCollectionObj[item.mark_id] = BillboardCollection.add(obj2.bill);
                LabelCollectionObj[item.mark_id] = LabelCollection.add(obj2.label)
              }
            }
          })
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //获取型号设备列表
    getDeviceList(dp_id,fun){
      var url = "/heating/zeiot/device/protocol/list?dp_id="+dp_id;
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.load_dev_index = this.load_dev_index+1;
          if(fun){
            fun(res.data)
          }
        }
      }).catch((res)=>{
        console.log(res);
        this.load_dev_index = this.load_dev_index+1;
        if(this.load_dev_index == this.lineData.length){
          this.getAlarmList();
        }
      })

    },
    //获取物联网型号列表
    getDPList(fun){
      var url = "/heating/device-model/easy-info";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          var dp_obj = {};
          res.data.forEach((item)=>{
            dp_obj[item.device_model_id] = item;
          })
          if(fun){
            fun(dp_obj)
          }
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    drawPoint() {
      BillboardCollection = new Cesium.BillboardCollection();
      viewer.scene.primitives.add(BillboardCollection);
      LabelCollection = new Cesium.LabelCollection();
      viewer.scene.primitives.add(LabelCollection);
    },
    setBillLabel(obj) {
      var bill = null;
      var label = null;
      bill = {
        show: true,
        id: "bill_" + obj.mark_id,
        width: 11,
        height: 11,
        position: Cesium.Cartesian3.fromDegrees(obj.mark_lon, obj.mark_lat, obj.mark_height ? obj.mark_height : 0),
        image: obj.device_model_data ? obj.device_model_data : require("../assets/images/point.png"),
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        color: Cesium.Color.fromCssColorString("rgba(82,255,255,0.7)")
      }
      if (obj.device_model_data) {
        delete bill.color
      }
      label = {
        show: obj.device_model_display_name?true:false,
        id: "label_" + obj.mark_id,
        text: obj.device_name,
        font: '14px sans-serif',
        position: Cesium.Cartesian3.fromDegrees(obj.mark_lon, obj.mark_lat, obj.mark_height ? obj.mark_height : 0),
        color: Cesium.Color.fromCssColorString("#ffffff"),
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        pixelOffset: new Cesium.Cartesian2(0, -16)
      }
      return {bill, label}
    },
    //获取报警列表
    getAlarmList(){
      var ids = [];
      this.lineData.forEach((item,index)=>{
        item.deviceList.forEach(val=>{
          ids.push(val.device_id);
        })
      })
      var start = this.$getTime((new Date()).getTime()-1000*60*60*24*10).t1;
      var end = this.$getTime(new Date()).t1;
      var url = "/heating/zeiot/project/heating/alarm-log?al_is_handle=0&begin="+start+'&end='+end;
      var data = ids;
      this.$axios.post(url,data).then((res)=>{
        if(res.status == 200){
          this.alarmList = res.data;
          this.alarmList.reverse()
        }
      }).catch((res)=>{
        console.log(res);
      })
      setTimeout(()=>{
        this.getAlarmList();
      },30000)
    },
    //获取设备在线数量
    getDeviceOnline(ids){
      var url = "/heating/zeiot/heat/read/device/onliny";
      var data = ids;
      this.$axios.post(url,data).then((res)=>{
        if(res.status == 200){
          this.device_online_num = res.data
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    //获取管线分类
    getLineClass(){
      var url = "/heating/line-group/info";
      this.$axios.get(url).then((res)=>{
        if(res.status == 200){
          this.classList = res.data;
          this.check_class_ids = [];
          this.classList.forEach((item)=>{
            this.check_class_ids.push(item.line_group_id)
            item.lineData = []
          })
        }
      }).catch((res)=>{
        console.log(res);
      })
    },
    showPoint(){
      this.show_point = !this.show_point;
      for(var key in BillboardCollectionObj){
        if(BillboardCollectionObj[key]){
          BillboardCollectionObj[key].show = this.show_point;
        }
        if(LabelCollectionObj[key]){
          LabelCollectionObj[key].show = this.show_point;
        }
      }
    },
    //点击管井点
    clickPoint(pick){
      var id = pick.id.split("bill_")[1];
      this.lineData.forEach((item)=>{
        item.deviceList.some((val)=>{
          if(val.device_id == id){
            var url = "";
            if(val.device_model_type == 1){//组态
              this.getDeviceInfo(id);
            }
            if(val.device_model_type == 2){//智慧供热大屏
              url = this.$router.resolve({name:"smartHeatingMonitoringSystem",
                query:{
                  key:this.$route.query.key,
                  name:val.device_name,
                  id:val.device_id,
                  dp_id:val.device_model_id
                }})
              window.open(url.href,"_blank");
            }
            return true
          }
        })
      })
    },
    //获取设备信息
    getDeviceInfo(device_id){
      var url = "/api/user/devices/"+device_id;
      this.$getZeiotApi(url,"get","",(res)=>{
        if(res.status == 200){
          if(res.data.device_monitor_id){
            var ip = this.$axios_ip.defaults.baseURL;
            window.open(ip+'/template/project/monitoring-list/simulated_scene.html?monitor_id=' + res.data.device_monitor_id + '&salt=' + this.$cookie.get("user_salt") ,"_blank")
          }else{

          }
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.left_cards{

  height: calc(100% - 0.9rem );
  overflow: hidden;
}
.left_card1{
  /*height: 2.30rem;*/
  height: calc(50% - 2.36rem);
}
.left_card2{
  height: 2.36rem;
  .left_card_cont{
    height: 2rem;
  }
}
.left_card3{
  /*height: 4.4rem;*/
  height: calc(50% - 0.2rem);
  margin-top: 0.20rem;
  .left_card_cont{
    height: calc(100% - 0.8rem);
    padding: 0.24rem 0.16rem;
    box-sizing: border-box;
    background: url("../assets/images/main/card_bg.png") center/100% 100% no-repeat;
  }
}
.right_cards{
  height: calc(100% - 0.9rem);
}
.right_card1{
  height: calc(50% - 0.3rem);
  .right_card_cont{
    height: 1.40rem;
    background: unset;
  }
  .right_card_cont2{
    height: calc(100% - 1.8rem);
    background: url("../assets/images/main/card_bg.png") center/100% 100% no-repeat;
    padding: 0.16rem 0.1rem;
  }
}
.right_card2{
  height: calc(25% - 0.1rem);
  .right_card_cont{
    height: calc(100% - 0.5rem);
  }
}
.right_card3{
  height: calc(25% - 0.1rem);
  .right_card_cont{
    height: calc(100% - 0.5rem);
  }
}

</style>