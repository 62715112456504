<template>
  <div class="main_page" style="overflow: auto">
    <main-header :title="tit"></main-header>
    <div class="header_time" style="right: 3rem">
      <i class="iconfont icon-rili"></i>
      <span>{{ time }}</span>
    </div>
    <div class="home_btns">
      <div class="def_btn_2" @click="toHome">
        <setting-outlined/>
        系统管理
      </div>
    </div>
    <div class="reportContainer ">
      <div class="selectCont">
        <div class="searchItem ">
          <span class="searchTit">用户名：</span>
          <div>{{ tit }}</div>
        </div>
        <div class="searchItem m_l_1">
          <span class="searchTit">时间间隔：</span>
          <a-select style="width: 208px" v-model:value="interval">
            <a-select-option value="">原始值</a-select-option>
            <a-select-option value="1分钟">1分钟</a-select-option>
            <a-select-option value="5分钟">5分钟</a-select-option>
            <a-select-option value="10分钟">10分钟</a-select-option>
            <a-select-option value="30分钟">30分钟</a-select-option>
            <a-select-option value="1小时">1小时</a-select-option>
            <a-select-option value="4小时">4小时</a-select-option>
            <a-select-option value="8小时">8小时</a-select-option>
            <a-select-option value="1天">1天</a-select-option>
          </a-select>
        </div>
        <div class="searchItem m_l_1">
          <span class="searchTit">日期：</span>
          <a-date-picker v-model:value="start" show-time/>
          <span>至</span>
          <a-date-picker v-model:value="end" show-time/>
        </div>
        <div class="searchItem m_l_1">
          <input class="check-custom" type="checkbox" v-model="show_curve" @change="chooseView">
          <span class="m_l_1">显示曲线</span>
        </div>
        <div class="searchItem m_l_1">
          <input class="check-custom" type="checkbox" v-model="show_table" @change="chooseView">
          <span class="m_l_1">显示数据</span>
        </div>
        <div class="searchItem m_l_1">
          <div class="def_btn_1" @click="search">查询</div>
        </div>
        <div class="searchItem m_l_1" style="position: absolute;right: 20px">
          <div class="def_btn_1" @click="exportFun">导出</div>
        </div>
      </div>
      <div id="echart1" style="width: 100%;height: 400px" v-show="show_curve"></div>

      <div id="tableCont" :style='{"margin":"0.2rem","overflow":"auto","position":"relative","height":table_h+"px"}'
           v-show="show_table">
        <table class="customTable" id="table_head" style="position: fixed">
          <thead>
          <tr>
            <th rowspan="2">日期</th>
            <th rowspan="2">分区</th>
            <th v-if="station_header_data.oneNetwork.length" :colspan="station_header_data.oneNetwork.length">
              一次网参数
            </th>
            <th rowspan="2" v-if="station_header_data.valvePos.length">{{ station_header_data.valvePos[0].name }}
              {{ station_header_data.valvePos[0].unit ? ("(" + station_header_data.valvePos[0].unit + ")") : "" }}
            </th>
            <th v-if="station_header_data.secNetwork.length" :colspan="station_header_data.secNetwork.length">
              二次网参数
            </th>
          </tr>
          <tr>
            <template v-for="(item,index) in station_header_data.oneNetwork" :key="index">
              <th>{{ item.name }} {{ item.unit ? ("(" + item.unit + ")") : "" }}</th>
            </template>
            <template v-for="(item,index) in station_header_data.secNetwork" :key="index">
              <th>{{ item.name }} {{ item.unit ? ("(" + item.unit + ")") : "" }}</th>
            </template>
          </tr>
          </thead>
        </table>
        <table class="customTable" id="myTable">
          <thead>
          <tr>
            <th rowspan="2">日期</th>
            <th rowspan="2">分区</th>
            <th v-if="station_header_data.oneNetwork.length" :colspan="station_header_data.oneNetwork.length">
              一次网参数
            </th>
            <th rowspan="2" v-if="station_header_data.valvePos.length">{{ station_header_data.valvePos[0].name }}
              {{ station_header_data.valvePos[0].unit ? ("(" + station_header_data.valvePos[0].unit + ")") : "" }}
            </th>
            <th v-if="station_header_data.secNetwork.length" :colspan="station_header_data.secNetwork.length">
              二次网参数
            </th>
          </tr>
          <tr>
            <template v-for="(item,index) in station_header_data.oneNetwork" :key="index">
              <th>{{ item.name }} {{ item.unit ? ("(" + item.unit + ")") : "" }}</th>
            </template>
            <template v-for="(item,index) in station_header_data.secNetwork" :key="index">
              <th>{{ item.name }} {{ item.unit ? ("(" + item.unit + ")") : "" }}</th>
            </template>
          </tr>
          </thead>
          <tbody>
          <template v-for="(item,index) in timeList" :key="index">
            <tr v-for="(val,key) in dpInfo.device_model_type" :key="key">
              <td :rowspan="dpInfo.device_model_type.length" v-if="key==0">{{ item.time }}</td>
              <td>{{ val }}</td>
              <template v-for="(v,k) in item.oneNetwork" :key="k">
                <td :rowspan="dpInfo.device_model_type.length" v-if="key==0">{{ v.pp_value[0] }}</td>
              </template>
              <template v-for="(v,k) in item.valvePos" :key="k">
                <td :rowspan="dpInfo.device_model_type.length" v-if="key == 0">{{ v.pp_value[key] }}</td>
              </template>
              <template v-for="(v,k) in item.secNetwork" :key="k">
                <td>{{ v.pp_value[key] }}</td>
              </template>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Dayjs from 'dayjs';
import $ from "jquery";
import * as echarts from "echarts";

var echart = null;

export default {
  name: "historicalData",
  data() {
    return {
      dp_id: "",
      id: "",
      name: "",
      time: "",
      tit: "",
      start: "",
      end: "",
      station_header_data: {
        dp_ids: [],
        lct: "1",
        czjb: "0",
        oneNetwork: [],
        secNetwork: [],
        valvePos: []
      },
      report_name: '',
      interval: "10分钟",
      paramIds: [],
      realData: {},
      station_data: {},
      timeList: [],
      dpInfo: {},
      sericesData: {},
      params: [],
      table_h: 300,
      show_curve: true,
      show_table: true,
    }
  },
  mounted() {
    this.$axios.defaults.headers.common["Authorization"] = "bearer " + this.$cookie.get("token");
    this.$axios.defaults.headers.common["blockcode"] = this.$route.query.key;
    this.id = this.$route.query.id;
    this.dp_id = this.$route.query.dp_id;
    this.name = this.$route.query.name;
    this.report_name = this.$route.query.report_name;
    this.time = this.$getTime(new Date()).t3;
    this.tit = this.$route.query.name;
    this.start = Dayjs(this.$getTime((new Date()).getTime() - 1000 * 60 * 60 * 24).t1)
    this.end = Dayjs()
    setTimeout(() => {
      this.time = this.$getTime(new Date()).t3;
    }, 1000)
    this.getDPList()
    this.getReportData()
  },
  methods: {
    toHome() {
      var url = this.$router.resolve({
        name: "home",
        query: {
          key: this.$route.query.key
        }
      })
      window.open(url.href, "_blank")
    },
    //温度曲线
    drawEchart1(data) {
      var sericesData = [];
      for (var key in data) {
        var Data = [];
        data[key].data.forEach((item) => {
          Data.push([item.time, item.value])
        })
        sericesData.push({
          name: data[key].pp_name,
          data: Data,
          type: 'line',
          smooth: true,
          lineStyle: {
            width: 1,
          },
        })
      }
      if (!echart) {
        echart = echarts.init(document.querySelector("#echart1"));
      }
      echart.clear();
      var option = {
        tooltip: {
          trigger: "axis"
        },
        color: ["#3786ce", "#7dbef4", "#7d8ef4", "#f4e27d", "#e29e3d", "#ff5b5b", "#7df48b", "#6cff00"],
        legend: {
          /*data: ['温度', '修正温度'],*/
          /*textStyle:{
              color:'#ffffff'
          },*/
          type:"scroll",
          pageTextStyle:{
            color:"#ffffff"
          },
          itemGap: 10,
          top: 10,
          right: 30,
          orient: "vertical",
          textStyle: {
            fontSize: this.$fontSize(14),
            color: '#ffffff',
          },
        },
        grid: {
          bottom: 20,
          top: 60,
          left: 40,
          right: 200,
          containLabel: true
        },
        xAxis: {
          type: 'time',
          boundaryGap: false,
          /*data: ["a", "b", "c"],*/
          axisLabel: {
            color: "#ffffff"
          },
          axisLine: {
            lineStyle: {
              color: "#113f7d"
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          name: "℃",
          nameTextStyle: {
            color: "#ffffff"
          },
          axisLabel: {
            color: "#ffffff"
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#113f7d"
            }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: "#182c50"
            }
          }
        },
        series: sericesData
      };
      echart.setOption(option);
    },
    //获取表格信息
    getReportData() {
      var url = "/heating/station-report/info?keyword=" + this.report_name;
      this.$axios.get(url).then((res) => {
        if (res.status == 200) {
          var pp_ids = [];
          this.reportData = res.data[0];
          this.station_header_data = this.reportData.station_header_data ? JSON.parse(this.reportData.station_header_data) : {
            dp_ids: [],
            lct: "1",
            czjb: "0",
            oneNetwork: [],
            secNetwork: [],
            valvePos: []
          }
          var station_data = this.reportData.station_data ? JSON.parse(this.reportData.station_data) : [];

          station_data.forEach((item, index) => {
            if (item.device_model_id == this.dp_id) {
              this.station_data = item;
              item.oneNetwork.forEach((val) => {
                pp_ids = pp_ids.concat(val.pp_id)
              })
              item.secNetwork.forEach((val) => {
                pp_ids = pp_ids.concat(val.pp_id)
              })
              item.valvePos.forEach((val) => {
                pp_ids = pp_ids.concat(val.pp_id)
              })
            }
          })
          for (var i = 0; i < pp_ids.length; i++) {
            if (!pp_ids[i]) {
              pp_ids.splice(i, 1);
              i--;
            }
          }
          this.getPPList(pp_ids);
        }
      }).catch((res) => {
        console.log(res);
      })
    },
    //获取参数列表
    getPPList(pp_ids) {
      this.params = [];
      this.sericesData = {};
      var url = "/heating/zeiot/heat/device/info";
      this.$axios.post(url, pp_ids).then((res) => {
        if (res.status == 200) {
          res.data.forEach((item) => {
            if (pp_ids.includes(item.dpp_id) && item.device_id == this.id) {
              this.params.push(item.pp_id);
              this.sericesData[item.pp_id] = {
                pp_id: item.pp_id,
                pp_name: item.pp_name,
                data: []
              }
              this.station_data.oneNetwork.forEach((val) => {
                val.pp_id[0] = val.pp_id[0] == item.dpp_id ? item.pp_id : val.pp_id[0]
              })
              this.station_data.secNetwork.forEach((val) => {
                val.pp_id.forEach((v, k) => {
                  val.pp_id[k] = v == item.dpp_id ? item.pp_id : val.pp_id[k]
                })
              })
              this.station_data.valvePos.forEach((val) => {
                val.pp_id.forEach((v, k) => {
                  val.pp_id[k] = v == item.dpp_id ? item.pp_id : v
                })
              })
            }
          })

          this.getHistoryData();
        }
      }).catch((res) => {
        console.log(res);
      })
    },
    //获取历史数据
    getHistoryData() {
      var duration = "";
      var durationStr = "";
      switch (this.interval) {
        case "":
          break;
        case "1分钟":
          duration = 1;
          durationStr = "分"
          break;
        case "5分钟":
          duration = 5;
          durationStr = "分"
          break;
        case "10分钟":
          duration = 10;
          durationStr = "分"
          break;
        case "30分钟":
          duration = 30;
          durationStr = "分"
          break;
        case "1小时":
          duration = 1;
          durationStr = "时"
          break;
        case "4小时":
          duration = 4;
          durationStr = "时"
          break;
        case "8小时":
          duration = 8;
          durationStr = "时"
          break;
        case "1天":
          duration = 1;
          durationStr = "日"
          break;
      }
      var paramIds = this.params;
      var url = "/api/history";
      var data = {
        "paramId": paramIds.join(","),
        "start": this.start ? this.$getTime(this.start.$d).t1 : "",
        "end": this.end ? this.$getTime(this.end.$d).t1 : "",
        "duration": duration,
        "durationStr": durationStr,
        "sort": "DESC"
      }
      this.$getZeiotApi(url, "post", JSON.stringify(data), (res) => {
        var serices_data = this.sericesData;
        var DATA = res.data.data.data;
        var data = [];
        for (var key in DATA) {
          if (serices_data[key]) {
            serices_data[key].data = DATA[key]
          }
          if (DATA[key].length > 0) {
            data = DATA[key];
          }
        }

        this.drawEchart1(serices_data)
        var timeList = [];
        data.forEach((item, index) => {
          var oneNetwork = JSON.parse(JSON.stringify(this.station_data.oneNetwork));
          var secNetwork = JSON.parse(JSON.stringify(this.station_data.secNetwork));
          var valvePos = JSON.parse(JSON.stringify(this.station_data.valvePos));
          oneNetwork.forEach((val) => [
            val.pp_value = [DATA[val.pp_id[0]] ? DATA[val.pp_id[0]][index].value : ""]
          ])
          secNetwork.forEach((val) => {
            val.pp_value = [];
            val.pp_id.forEach((v) => {
              val.pp_value.push(DATA[v] ? DATA[v][index].value : "")
            })
          })
          valvePos.forEach((val) => {
            val.pp_value = [];
            val.pp_id.forEach((v) => {
              val.pp_value.push(DATA[v] ? DATA[v][index].value : "")
            })
          })
          timeList.push({
            time: item.time,
            oneNetwork,
            secNetwork,
            valvePos
          })
        })
        this.timeList = timeList;
        this.chooseView();
      }, (res) => {
        console.log(res)
      })
    },
    //获取型号列表
    getDPList() {
      var url = "/heating/device-model/easy-info/part";
      this.$axios.get(url).then((res) => {
        if (res.status == 200) {
          res.data.forEach((item) => {
            if (item.device_model_id == this.dp_id) {
              this.dpInfo = item;
              this.dpInfo.device_model_type = [];
              item.parts.forEach((val) => {
                this.dpInfo.device_model_type.push(val.device_model_partition_name)
              })
            }
          })
        }
      }).catch((res) => {
        console.log(res);
      })
    },
    search() {
      this.getHistoryData()
    },
    setWidth() {
      setTimeout(() => {
        $("#table_head").width($("#myTable").width())
        var ths = $("#myTable").find("thead tr th");
        var th_s = $("#table_head").find("thead tr th");
        th_s.each((k) => {
          $(th_s[k]).width($(ths[k]).width())
        })
      }, 100)
    },
    chooseView() {
      var table_h = 300;
      var echart_h = this.show_curve ? $("#echart1").height() : 0;
      table_h = $(document).height() - echart_h - $(".mian_header").height() - $(".selectCont").height() - 40
      console.log(table_h, echart_h, $(".mian_header").height(), $(".selectCont").height())
      this.table_h = table_h;
      this.setWidth()
    },
    //导出
    exportFun() {
      var cells = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","AA","AB","AC","AD","AE","AF","AG"];
      var headers1 = ["日期","分区"];
      var headers2 = ["日期","分区"];
      //一次网表头
      this.station_header_data.oneNetwork.forEach((item)=>{
        headers2.push(item.name+(item.unit?("("+item.unit+")"):""));
        headers1.push("一次网参数");
      })
      //阀位
      this.station_header_data.valvePos.forEach((item)=>{
        headers2.push(item.name+(item.unit?("("+item.unit+")"):""));
        headers1.push("阀位"+(item.unit?("("+item.unit+")"):""));
      })
      //二次网表头
      this.station_header_data.secNetwork.forEach((item)=>{
        headers2.push(item.name+(item.unit?("("+item.unit+")"):""));
        headers1.push("二次网参数");
      })

      //return false
      //创建一个新的工作簿表
      const workbook = new ExcelJS.Workbook()
      //创建一个新的工作表
      const worksheet = workbook.addWorksheet('My Sheet')
      //可选  单元格的默认宽度
      worksheet.properties.defaultColWidth = 20
//可选  单独设置第五列的宽度
      worksheet.getColumn(5).width = 10
//添加表头到工作表
      //表头
      worksheet.addRow(headers1);
      worksheet.addRow(headers2);
      worksheet.mergeCells(`A1:A2`);
      worksheet.mergeCells(`B1:B2`);
      worksheet.mergeCells(`C1:${cells[1+this.station_header_data.oneNetwork.length]}1`);
      var start = 2+this.station_header_data.oneNetwork.length;
      var end = 1+this.station_header_data.valvePos.length+this.station_header_data.oneNetwork.length;
      worksheet.mergeCells(`${cells[start]}1:${cells[end]}2`);
      start = 2+this.station_header_data.valvePos.length+this.station_header_data.oneNetwork.length;
      end = 1+this.station_header_data.valvePos.length+this.station_header_data.oneNetwork.length+this.station_header_data.secNetwork.length;
      worksheet.mergeCells(`${cells[start]}1:${cells[end]}1`);

      //表格内容
      var newData = [];
      var row = 3;
      this.timeList.forEach((item,index)=>{
        this.dpInfo.device_model_type.forEach((val,key)=>{
          var arr = [item.time,val];
          item.oneNetwork.forEach((v,k)=>{
            arr.push(v.pp_value[0]);
          })
          item.valvePos.forEach((v,k)=>{
            arr.push(v.pp_value[0]);
          })
          item.secNetwork.forEach((v,k)=>{
            arr.push(v.pp_value[key]);
          })
          newData.push(arr);
          worksheet.addRow(arr);
        })
        //合并行 从第三行开始
        worksheet.mergeCells(`A${row}:A${row+this.dpInfo.device_model_type.length-1}`);
        //一次网参数
        this.station_header_data.oneNetwork.forEach((val,key)=>{
          worksheet.mergeCells(`${cells[key+2]}${row}:${cells[key+2]}${row+this.dpInfo.device_model_type.length-1}`);
        });
        //阀位
        this.station_header_data.valvePos.forEach((val,key)=>{
          worksheet.mergeCells(`${cells[key+2+this.station_header_data.oneNetwork.length]}${row}:${cells[key+2+this.station_header_data.oneNetwork.length]}${row+this.dpInfo.device_model_type.length-1}`);
        });

        row = row+this.dpInfo.device_model_type.length;
      })

      //worksheet.addRows(newData)

      //worksheet.addRows(fromatterData(headers,newData));
//可选  给每一行设置行高
      worksheet.eachRow(row => {
        row.height = 20;
        row._cells.forEach((item)=>{
          item.style={
            alignment: {
              horizontal: "center", // 水平居中
              vertical: "middle", // 垂直居中
            },
          }
        })

      });
//可选 单独设置某一行的行高
      worksheet.getRow(1).height = 25;
// 将工作簿保存到本地文件
      workbook.xlsx.writeBuffer().then(buffer => {
//这行代码创建了一个Blob对象，它基于给定的二进制数据buffer，
//这个二进制数据是一个Excel文件的内容，
//Blob的MIME类型被设置为
//"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"，
//表示这是一种Excel的文件格式。
//一个Blob对象是一个不可变的二进制数据对象，它表示了一段数据，可以通过URL.createObjectURL()方法创建一个URL来访问它。
//在这种情况下，这个Blob对象可以被用来下载Excel文件，或者上传到服务器上进行处理。
        const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', '历史数据.xlsx');
        document.body.appendChild(link);
        link.click();
      })

      function fromatterData(headers, rwos) {
        return rwos.map(item => {
          return Object.keys(headers).map(key => {
            //判断是否是时间字段
            if (cols_date.includes(headers[key])) {
              if (item[headers[key]] == null) {
                return item[headers[key]]
              } else {
                return formatDate('yyyy-MM-dd hh:mm:ss', item[headers[key]])
              }

            }
            return item[headers[key]]
          })
        })
      }

      function formatDate(format, date, settings) {
        if (!date) {
          return "";
        }

        var iFormat,
            dayNamesShort = (settings ? settings.dayNamesShort : null) || this._defaults.dayNamesShort,
            dayNames = (settings ? settings.dayNames : null) || this._defaults.dayNames,
            monthNamesShort = (settings ? settings.monthNamesShort : null) || this._defaults.monthNamesShort,
            monthNames = (settings ? settings.monthNames : null) || this._defaults.monthNames,
            // Check whether a format character is doubled
            lookAhead = function (match) {
              var matches = (iFormat + 1 < format.length && format.charAt(iFormat + 1) === match);
              if (matches) {
                iFormat++;
              }
              return matches;
            },
            // Format a number, with leading zero if necessary
            formatNumber = function (match, value, len) {
              var num = "" + value;
              if (lookAhead(match)) {
                while (num.length < len) {
                  num = "0" + num;
                }
              }
              return num;
            },
            // Format a name, short or long as requested
            formatName = function (match, value, shortNames, longNames) {
              return (lookAhead(match) ? longNames[value] : shortNames[value]);
            },
            output = "",
            literal = false;

        if (date) {
          for (iFormat = 0; iFormat < format.length; iFormat++) {
            if (literal) {
              if (format.charAt(iFormat) === "'" && !lookAhead("'")) {
                literal = false;
              } else {
                output += format.charAt(iFormat);
              }
            } else {
              switch (format.charAt(iFormat)) {
                case "d":
                  output += formatNumber("d", date.getDate(), 2);
                  break;
                case "D":
                  output += formatName("D", date.getDay(), dayNamesShort, dayNames);
                  break;
                case "o":
                  output += formatNumber("o",
                      Math.round((new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime() - new Date(date.getFullYear(), 0, 0).getTime()) / 86400000), 3);
                  break;
                case "m":
                  output += formatNumber("m", date.getMonth() + 1, 2);
                  break;
                case "M":
                  output += formatName("M", date.getMonth(), monthNamesShort, monthNames);
                  break;
                case "y":
                  output += (lookAhead("y") ? date.getFullYear() :
                      (date.getYear() % 100 < 10 ? "0" : "") + date.getYear() % 100);
                  break;
                case "@":
                  output += date.getTime();
                  break;
                case "!":
                  output += date.getTime() * 10000 + this._ticksTo1970;
                  break;
                case "'":
                  if (lookAhead("'")) {
                    output += "'";
                  } else {
                    literal = true;
                  }
                  break;
                default:
                  output += format.charAt(iFormat);
              }
            }
          }
        }
        return output;
      }
    }

  }
}
</script>

<style scoped>
.main_page {
  background: #0a143f;
}

.searchItem {
  font-size: 0.14rem;
  color: #ffffff;
}

</style>